// display graph

// Path: src/Components/CompanyGraph.tsx

import React from "react";
import {Typography, Grid, Box} from '@mui/material';
import { Company } from "../Models/Company";
import { DetailedCompany } from "../Models/DetailedCompany";
import { Bar } from 'react-chartjs-2';
import { Paper } from '@mui/material';
import ChartDataLabels from "chartjs-plugin-datalabels";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    LineElement,
    PointElement,
    RadialLinearScale
  } from 'chart.js';
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";

function DetailedCompanyGraph(props: {company: DetailedCompany}): JSX.Element {

    ChartJS.register(ChartDataLabels);

    // Change default options for ALL charts
    ChartJS.defaults.set('plugins.datalabels', {
        color: '#ffffff',
        anchor: "end",
        align: "start",
    });

    const [labels, setLabels] = useState<string[]>([]);
    const [coreData, setCoreData] = useState<string[]>([]);
    const [valuesData, setValuesData] = useState<string[]>([]);

    useEffect(() => {


        let this_labels = [];
        let this_coreData = [];
        let this_valuesData = [];


        let LegalScoreCore = props.company.LegalSupplierScoreCore + props.company.LegalStaffScoreCore;
        let LegalPossibleCore = props.company.LegalSupplierPossibleCore + props.company.LegalStaffPossibleCore;
        let LegalScoreValues = props.company.LegalSupplierScoreValues + props.company.LegalStaffScoreValues;
        let LegalPossibleValues = props.company.LegalSupplierPossibleValues + props.company.LegalStaffPossibleValues;
        let LegalPossible = LegalPossibleCore + LegalPossibleValues;

        if (LegalPossible > 0) {
            if (LegalPossibleCore > 0) {
                this_coreData.push(Math.round((LegalScoreCore / LegalPossibleCore) * 100).toString());
            }
            else{
                this_coreData.push('n/a');
            }
            if (LegalPossibleValues > 0) {
                this_valuesData.push(Math.round((LegalScoreValues / LegalPossibleValues) * 100).toString());
            }
            else{
                this_valuesData.push('n/a');
            }
            this_labels.push('Legal');
        }


        let OwnershipScoreCore = props.company.OwnershipSupplierScoreCore + props.company.OwnershipStaffScoreCore;
        let OwnershipPossibleCore = props.company.OwnershipSupplierPossibleCore + props.company.OwnershipStaffPossibleCore;
        let OwnershipScoreValues = props.company.OwnershipSupplierScoreValues + props.company.OwnershipStaffScoreValues;
        let OwnershipPossibleValues = props.company.OwnershipSupplierPossibleValues + props.company.OwnershipStaffPossibleValues;
        let OwnershipPossible = OwnershipPossibleCore + OwnershipPossibleValues;

        if (OwnershipPossible > 0) {
            if (OwnershipPossibleCore > 0) {
                this_coreData.push(Math.round((OwnershipScoreCore / OwnershipPossibleCore) * 100).toString());
            }
            else{
                this_coreData.push('n/a');
            }
            if (OwnershipPossibleValues > 0) {
                this_valuesData.push(Math.round((OwnershipScoreValues / OwnershipPossibleValues) * 100).toString());
            }
            else{
                this_valuesData.push('n/a');
            }
            this_labels.push('Ownership');
        }


        let EquityScoreCore = props.company.EquitySupplierScoreCore + props.company.EquityStaffScoreCore;
        let EquityPossibleCore = props.company.EquitySupplierPossibleCore + props.company.EquityStaffPossibleCore;
        let EquityScoreValues = props.company.EquitySupplierScoreValues + props.company.EquityStaffScoreValues;
        let EquityPossibleValues = props.company.EquitySupplierPossibleValues + props.company.EquityStaffPossibleValues;
        let EquityPossible = EquityPossibleCore + EquityPossibleValues;

        if (EquityPossible > 0) {
            if (EquityPossibleCore > 0) {
                this_coreData.push(Math.round((EquityScoreCore / EquityPossibleCore) * 100).toString());
            }
            else{
                this_coreData.push('n/a');
            }
            if (EquityPossibleValues > 0) {
                this_valuesData.push(Math.round((EquityScoreValues / EquityPossibleValues) * 100).toString());
            }
            else{
                this_valuesData.push('n/a');
            }
            this_labels.push('Equity');
        }


        let SkillsDevelopmentScoreCore = props.company.SkillsDevelopmentSupplierScoreCore + props.company.SkillsDevelopmentStaffScoreCore;
        let SkillsDevelopmentPossibleCore = props.company.SkillsDevelopmentSupplierPossibleCore + props.company.SkillsDevelopmentStaffPossibleCore;
        let SkillsDevelopmentScoreValues = props.company.SkillsDevelopmentSupplierScoreValues + props.company.SkillsDevelopmentStaffScoreValues;
        let SkillsDevelopmentPossibleValues = props.company.SkillsDevelopmentSupplierPossibleValues + props.company.SkillsDevelopmentStaffPossibleValues;
        let SkillsDevelopmentPossible = SkillsDevelopmentPossibleCore + SkillsDevelopmentPossibleValues;

        if (SkillsDevelopmentPossible > 0) {
            if (SkillsDevelopmentPossibleCore > 0) {
                this_coreData.push(Math.round((SkillsDevelopmentScoreCore / SkillsDevelopmentPossibleCore) * 100).toString());
            }
            else{
                this_coreData.push('n/a');
            }
            if (SkillsDevelopmentPossibleValues > 0) {
                this_valuesData.push(Math.round((SkillsDevelopmentScoreValues / SkillsDevelopmentPossibleValues) * 100).toString());
            }
            else{
                this_valuesData.push('n/a');
            }
            this_labels.push('Skills Development');
        }


        let HRScoreCore = props.company.HRSupplierScoreCore + props.company.HRStaffScoreCore;
        let HRPossibleCore = props.company.HRSupplierPossibleCore + props.company.HRStaffPossibleCore;
        let HRScoreValues = props.company.HRSupplierScoreValues + props.company.HRStaffScoreValues;
        let HRPossibleValues = props.company.HRSupplierPossibleValues + props.company.HRStaffPossibleValues;
        let HRPossible = HRPossibleCore + HRPossibleValues;

        if (HRPossible > 0) {
            if (HRPossibleCore > 0) {
                this_coreData.push(Math.round((HRScoreCore / HRPossibleCore) * 100).toString());
            }
            else{
                this_coreData.push('n/a');
            }
            if (HRPossibleValues > 0) {
                this_valuesData.push(Math.round((HRScoreValues / HRPossibleValues) * 100).toString());
            }
            else{
                this_valuesData.push('n/a');
            }
            this_labels.push('HR');
        }


        let SocialInvestmentScoreCore = props.company.SocialInvestmentSupplierScoreCore + props.company.SocialInvestmentStaffScoreCore;
        let SocialInvestmentPossibleCore = props.company.SocialInvestmentSupplierPossibleCore + props.company.SocialInvestmentStaffPossibleCore;
        let SocialInvestmentScoreValues = props.company.SocialInvestmentSupplierScoreValues + props.company.SocialInvestmentStaffScoreValues;
        let SocialInvestmentPossibleValues = props.company.SocialInvestmentSupplierPossibleValues + props.company.SocialInvestmentStaffPossibleValues;
        let SocialInvestmentPossible = SocialInvestmentPossibleCore + SocialInvestmentPossibleValues;

        if (SocialInvestmentPossible > 0) {
            if (SocialInvestmentPossibleCore > 0) {
                this_coreData.push(Math.round((SocialInvestmentScoreCore / SocialInvestmentPossibleCore) * 100).toString());
            }
            else{
                this_coreData.push('n/a');
            }
            if (SocialInvestmentPossibleValues > 0) {
                this_valuesData.push(Math.round((SocialInvestmentScoreValues / SocialInvestmentPossibleValues) * 100).toString());
            }
            else{
                this_valuesData.push('n/a');
            }
            this_labels.push('Social Investment');
        }


        let ProcurementScoreCore = props.company.ProcurementSupplierScoreCore + props.company.ProcurementStaffScoreCore;
        let ProcurementPossibleCore = props.company.ProcurementSupplierPossibleCore + props.company.ProcurementStaffPossibleCore;
        let ProcurementScoreValues = props.company.ProcurementSupplierScoreValues + props.company.ProcurementStaffScoreValues;
        let ProcurementPossibleValues = props.company.ProcurementSupplierPossibleValues + props.company.ProcurementStaffPossibleValues;
        let ProcurementPossible = ProcurementPossibleCore + ProcurementPossibleValues;

        if (ProcurementPossible > 0) {
            if (ProcurementPossibleCore > 0) {
                this_coreData.push(Math.round((ProcurementScoreCore / ProcurementPossibleCore) * 100).toString());
            }
            else{
                this_coreData.push('n/a');
            }
            if (ProcurementPossibleValues > 0) {
                this_valuesData.push(Math.round((ProcurementScoreValues / ProcurementPossibleValues) * 100).toString());
            }
            else{
                this_valuesData.push('n/a');
            }
            this_labels.push('Procurement');
        }


        let HealthScoreCore = props.company.HealthSupplierScoreCore + props.company.HealthStaffScoreCore;
        let HealthPossibleCore = props.company.HealthSupplierPossibleCore + props.company.HealthStaffPossibleCore;
        let HealthScoreValues = props.company.HealthSupplierScoreValues + props.company.HealthStaffScoreValues;
        let HealthPossibleValues = props.company.HealthSupplierPossibleValues + props.company.HealthStaffPossibleValues;
        let HealthPossible = HealthPossibleCore + HealthPossibleValues;

        if (HealthPossible > 0) {
            if (HealthPossibleCore > 0) {
                this_coreData.push(Math.round((HealthScoreCore / HealthPossibleCore) * 100).toString());
            }
            else{
                this_coreData.push('n/a');
            }
            if (HealthPossibleValues > 0) {
                this_valuesData.push(Math.round((HealthScoreValues / HealthPossibleValues) * 100).toString());
            }
            else{
                this_valuesData.push('n/a');
            }
            this_labels.push('Health');
        }


        let EnvironmentScoreCore = props.company.EnvironmentSupplierScoreCore + props.company.EnvironmentStaffScoreCore;
        let EnvironmentPossibleCore = props.company.EnvironmentSupplierPossibleCore + props.company.EnvironmentStaffPossibleCore;
        let EnvironmentScoreValues = props.company.EnvironmentSupplierScoreValues + props.company.EnvironmentStaffScoreValues;
        let EnvironmentPossibleValues = props.company.EnvironmentSupplierPossibleValues + props.company.EnvironmentStaffPossibleValues;
        let EnvironmentPossible = EnvironmentPossibleCore + EnvironmentPossibleValues;

        if (EnvironmentPossible > 0) {
            if (EnvironmentPossibleCore > 0) {
                this_coreData.push(Math.round((EnvironmentScoreCore / EnvironmentPossibleCore) * 100).toString());
            }
            else{
                this_coreData.push('n/a');
            }
            if (EnvironmentPossibleValues > 0) {
                this_valuesData.push(Math.round((EnvironmentScoreValues / EnvironmentPossibleValues) * 100).toString());
            }
            else{
                this_valuesData.push('n/a');
            }
            this_labels.push('Environment');
        }


        let WorkplaceScoreCore = props.company.WorkplaceSupplierScoreCore + props.company.WorkplaceStaffScoreCore;
        let WorkplacePossibleCore = props.company.WorkplaceSupplierPossibleCore + props.company.WorkplaceStaffPossibleCore;
        let WorkplaceScoreValues = props.company.WorkplaceSupplierScoreValues + props.company.WorkplaceStaffScoreValues;
        let WorkplacePossibleValues = props.company.WorkplaceSupplierPossibleValues + props.company.WorkplaceStaffPossibleValues;
        let WorkplacePossible = WorkplacePossibleCore + WorkplacePossibleValues;

        if (WorkplacePossible > 0) {
            if (WorkplacePossibleCore > 0) {
                this_coreData.push(Math.round((WorkplaceScoreCore / WorkplacePossibleCore) * 100).toString());
            }
            else{
                this_coreData.push('n/a');
            }
            if (WorkplacePossibleValues > 0) {
                this_valuesData.push(Math.round((WorkplaceScoreValues / WorkplacePossibleValues) * 100).toString());
            }
            else{
                this_valuesData.push('n/a');
            }
            this_labels.push('Workplace');
        }


        let HIVScoreCore = props.company.HIVSupplierScoreCore + props.company.HIVStaffScoreCore;
        let HIVPossibleCore = props.company.HIVSupplierPossibleCore + props.company.HIVStaffPossibleCore;
        let HIVScoreValues = props.company.HIVSupplierScoreValues + props.company.HIVStaffScoreValues;
        let HIVPossibleValues = props.company.HIVSupplierPossibleValues + props.company.HIVStaffPossibleValues;
        let HIVPossible = HIVPossibleCore + HIVPossibleValues;

        if (HIVPossible > 0) {
            if (HIVPossibleCore > 0) {
                this_coreData.push(Math.round((HIVScoreCore / HIVPossibleCore) * 100).toString());
            }
            else{
                this_coreData.push('n/a');
            }
            if (HIVPossibleValues > 0) {
                this_valuesData.push(Math.round((HIVScoreValues / HIVPossibleValues) * 100).toString());
            }
            else{
                this_valuesData.push('n/a');
            }
            this_labels.push('HIV');
        }

        





        setLabels(this_labels);
        setCoreData(this_coreData);
        setValuesData(this_valuesData);
    }, [props.company]);





    return (


                    <Paper elevation={0} sx={{
                        p: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        borderRadius: 2,
                        height:"60vh",
                        }}
                    >

                        <Bar
                            data={{
                                labels: labels,
                                datasets: [
                                    {
                                        label: 'Core Compliance',
                                        data: coreData,
                                        borderColor: '#FD524C',
                                        backgroundColor: '#FD524C',
                                        borderWidth: 1,
                                    },
                                    {
                                        label: 'Values Alignment',
                                        data: valuesData,
                                        borderColor: '#329198',
                                        backgroundColor: '#329198',
                                        borderWidth: 1,
                                    },
                                ],
                            }}

                            options={
                                
                                {
                                    maintainAspectRatio: false,
                                    indexAxis: 'y' as const,
                                    elements: {
                                      bar: {
                                        borderWidth: 1,
                                      },
                                    },
                                    responsive: true,
                                    plugins: {
                                
                                      legend: {
                                        position: 'bottom' as const,
                                      },
                                      title: {
                                        display: false,
                                        text: '',
                                      },
                                      datalabels: {
                                          formatter: function(value:number) {
                                            
                                                //set the value to 1 decimal at most
                                                // return Math.round(value * 10) / 10 +'%';
                                                return Math.round(value) +'%';
                                          }
                                      }
                                    },
                                }
                            }
                        />
                    </Paper>


    );
}

export default DetailedCompanyGraph;
