// display graph

// Path: src/Components/CompanyGraph.tsx

import React from "react";
import {Typography, Grid, Box} from '@mui/material';
import { Company } from "../Models/Company";
import { Bar } from 'react-chartjs-2';
import { Paper } from '@mui/material';
import ChartDataLabels from "chartjs-plugin-datalabels";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    LineElement,
    PointElement,
    RadialLinearScale
  } from 'chart.js';
import { Link } from "react-router-dom";

function CompanyGraph(props: {company: Company}): JSX.Element {

    ChartJS.register(ChartDataLabels);

    // Change default options for ALL charts
    ChartJS.defaults.set('plugins.datalabels', {
        color: '#333333',
        anchor: "end",
        align: "end",
    });

    return (


                    <Paper elevation={0} sx={{
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        borderRadius: 2,
                        height:"60vh",
                        }}
                    >

                        <Bar
                            data={{
                                labels: ['Legal', 'Ownership','Equity','Skills Development', 'HR', 'Social Investment',  'Procurement',  'Health', 'Environment',  'Workplace', 'HIV'],
                                datasets: [
                                    {
                                        label: 'Core Compliance',
                                        data: [props.company.LegalCoreCompliance, props.company.OwnershipCoreCompliance, props.company.EquityCoreCompliance, props.company.SkillsDevelopmentCoreCompliance, props.company.HRCoreCompliance, props.company.SocialInvestmentCoreCompliance, props.company.ProcurementCoreCompliance, props.company.HealthCoreCompliance, props.company.EnvironmentCoreCompliance, props.company.WorkplaceCoreCompliance, props.company.HIVCoreCompliance],
                                        borderColor: '#FD524C',
                                        backgroundColor: '#FD524C',
                                        borderWidth: 1,
                                    },
                                    {
                                        label: 'Values Alignment',
                                        data: [props.company.LegalValuesAlignment, props.company.OwnershipValuesAlignment, props.company.EquityValuesAlignment, props.company.SkillsDevelopmentValuesAlignment, props.company.HRValuesAlignment, props.company.SocialInvestmentValuesAlignment, props.company.ProcurementValuesAlignment, props.company.HealthValuesAlignment, props.company.EnvironmentValuesAlignment, props.company.WorkplaceValuesAlignment, props.company.HIVValuesAlignment],
                                        borderColor: '#DEE700',
                                        backgroundColor: '#DEE700',
                                        borderWidth: 1,
                                    },
                                ],
                            }}

                            options={
                                
                                {
                                    maintainAspectRatio: false,
                                    indexAxis: 'y' as const,
                                    elements: {
                                      bar: {
                                        borderWidth: 1,
                                      },
                                    },
                                    responsive: true,
                                    plugins: {
                                
                                      legend: {
                                        position: 'bottom' as const,
                                      },
                                      title: {
                                        display: false,
                                        text: '',
                                      },
                                      datalabels: {
                                          formatter: function(value:number) {
                                            
                                                //set the value to 1 decimal at most
                                                return Math.round(value * 10) / 10 +'%';
                                          }
                                      }
                                    },
                                }
                            }
                        />
                    </Paper>


    );
}

export default CompanyGraph;
