// component to display the CompanyInfo and CompanyFixes fields

import React from "react";
import { DetailedCompany } from "../Models/DetailedCompany";
import {useParams} from "react-router-dom";
import { useState, useEffect } from "react";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Paper, Grid, InputLabel, MenuItem, Button, FormControl, Box, Typography, Dialog, DialogContent, DialogActions } from "@mui/material";
import CompanyGraph from "../Components/CompanyGraph";
import DetailedCompanyGraph from "../Components/DetailedCompanyGraph";
import SupplierStaffCompanyGraph from "../Components/SupplierStaffCompanyGraph";


function CompanyDetails(): JSX.Element {

    // read an environment variable
    const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
    const GOOGLE_SHEET = process.env.REACT_APP_GOOGLE_SHEET;
    const GOOGLE_SHEET_TAB = process.env.REACT_APP_GOOGLE_SHEET_TAB;
    const GOOGLE_SHEET_TAB2 = 'ETL';
    
    // get the companies data
    const [companies, setCompanies] = useState<DetailedCompany[]>([]);
    const [company, setCompany] = useState<DetailedCompany>();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    function parseAndConvert(input: string) {
        const parsedNumber = parseInt(input, 10);
        return isNaN(parsedNumber) ? 0 : parsedNumber;
      }

    useEffect(() => {
        fetch(`https://sheets.googleapis.com/v4/spreadsheets/${GOOGLE_SHEET}/values/${GOOGLE_SHEET_TAB2}?key=${GOOGLE_API_KEY}`)
            .then((response) => response.json())
            .then((data) => {

                // parse the data into the company model
                let companies_array: DetailedCompany[] = [];
                let rows = data.values;
                for (let i = 1; i < rows.length; i++) {
                    let row = rows[i];
                    let this_company: DetailedCompany = {
                        Size: row[0],
                        Name: row[1],
                        LegalSupplierScoreCore: parseAndConvert(row[2]),
                        LegalSupplierPossibleCore: parseAndConvert(row[3]),
                        LegalStaffScoreCore: parseAndConvert(row[4]),
                        LegalStaffPossibleCore: parseAndConvert(row[5]),
                        OwnershipSupplierScoreCore: parseAndConvert(row[6]),
                        OwnershipSupplierPossibleCore: parseAndConvert(row[7]),
                        OwnershipStaffScoreCore: parseAndConvert(row[8]),
                        OwnershipStaffPossibleCore: parseAndConvert(row[9]),
                        EquitySupplierScoreCore: parseAndConvert(row[10]),
                        EquitySupplierPossibleCore: parseAndConvert(row[11]),
                        EquityStaffScoreCore: parseAndConvert(row[12]),
                        EquityStaffPossibleCore: parseAndConvert(row[13]),
                        SkillsDevelopmentSupplierScoreCore: parseAndConvert(row[14]),
                        SkillsDevelopmentSupplierPossibleCore: parseAndConvert(row[15]),
                        SkillsDevelopmentStaffScoreCore: parseAndConvert(row[16]),
                        SkillsDevelopmentStaffPossibleCore: parseAndConvert(row[17]),
                        HRSupplierScoreCore: parseAndConvert(row[18]),
                        HRSupplierPossibleCore: parseAndConvert(row[19]),
                        HRStaffScoreCore: parseAndConvert(row[20]),
                        HRStaffPossibleCore: parseAndConvert(row[21]),
                        SocialInvestmentSupplierScoreCore: parseAndConvert(row[22]),
                        SocialInvestmentSupplierPossibleCore: parseAndConvert(row[23]),
                        SocialInvestmentStaffScoreCore: parseAndConvert(row[24]),
                        SocialInvestmentStaffPossibleCore: parseAndConvert(row[25]),
                        ProcurementSupplierScoreCore: parseAndConvert(row[26]),
                        ProcurementSupplierPossibleCore: parseAndConvert(row[27]),
                        ProcurementStaffScoreCore: parseAndConvert(row[28]),
                        ProcurementStaffPossibleCore: parseAndConvert(row[29]),
                        HealthSupplierScoreCore: parseAndConvert(row[30]),
                        HealthSupplierPossibleCore: parseAndConvert(row[31]),
                        HealthStaffScoreCore: parseAndConvert(row[32]),
                        HealthStaffPossibleCore: parseAndConvert(row[33]),
                        EnvironmentSupplierScoreCore: parseAndConvert(row[34]),
                        EnvironmentSupplierPossibleCore: parseAndConvert(row[35]),
                        EnvironmentStaffScoreCore: parseAndConvert(row[36]),
                        EnvironmentStaffPossibleCore: parseAndConvert(row[37]),
                        WorkplaceSupplierScoreCore: parseAndConvert(row[38]),
                        WorkplaceSupplierPossibleCore: parseAndConvert(row[39]),
                        WorkplaceStaffScoreCore: parseAndConvert(row[40]),
                        WorkplaceStaffPossibleCore: parseAndConvert(row[41]),
                        HIVSupplierScoreCore: parseAndConvert(row[42]),
                        HIVSupplierPossibleCore: parseAndConvert(row[43]),
                        HIVStaffScoreCore: parseAndConvert(row[44]),
                        HIVStaffPossibleCore: parseAndConvert(row[45]),
                        LegalSupplierScoreValues: parseAndConvert(row[46]),
                        LegalSupplierPossibleValues: parseAndConvert(row[47]),
                        LegalStaffScoreValues: parseAndConvert(row[48]),
                        LegalStaffPossibleValues: parseAndConvert(row[49]),
                        OwnershipSupplierScoreValues: parseAndConvert(row[50]),
                        OwnershipSupplierPossibleValues: parseAndConvert(row[51]),
                        OwnershipStaffScoreValues: parseAndConvert(row[52]),
                        OwnershipStaffPossibleValues: parseAndConvert(row[53]),
                        EquitySupplierScoreValues: parseAndConvert(row[54]),
                        EquitySupplierPossibleValues: parseAndConvert(row[55]),
                        EquityStaffScoreValues: parseAndConvert(row[56]),
                        EquityStaffPossibleValues: parseAndConvert(row[57]),
                        SkillsDevelopmentSupplierScoreValues: parseAndConvert(row[58]),
                        SkillsDevelopmentSupplierPossibleValues: parseAndConvert(row[59]),
                        SkillsDevelopmentStaffScoreValues: parseAndConvert(row[60]),
                        SkillsDevelopmentStaffPossibleValues: parseAndConvert(row[61]),
                        HRSupplierScoreValues: parseAndConvert(row[62]),
                        HRSupplierPossibleValues: parseAndConvert(row[63]),
                        HRStaffScoreValues: parseAndConvert(row[64]),
                        HRStaffPossibleValues: parseAndConvert(row[65]),
                        SocialInvestmentSupplierScoreValues: parseAndConvert(row[66]),
                        SocialInvestmentSupplierPossibleValues: parseAndConvert(row[67]),
                        SocialInvestmentStaffScoreValues: parseAndConvert(row[68]),
                        SocialInvestmentStaffPossibleValues: parseAndConvert(row[69]),
                        ProcurementSupplierScoreValues: parseAndConvert(row[70]),
                        ProcurementSupplierPossibleValues: parseAndConvert(row[71]),
                        ProcurementStaffScoreValues: parseAndConvert(row[72]),
                        ProcurementStaffPossibleValues: parseAndConvert(row[73]),
                        HealthSupplierScoreValues: parseAndConvert(row[74]),
                        HealthSupplierPossibleValues: parseAndConvert(row[75]),
                        HealthStaffScoreValues: parseAndConvert(row[76]),
                        HealthStaffPossibleValues: parseAndConvert(row[77]),
                        EnvironmentSupplierScoreValues: parseAndConvert(row[78]),
                        EnvironmentSupplierPossibleValues: parseAndConvert(row[79]),
                        EnvironmentStaffScoreValues: parseAndConvert(row[80]),
                        EnvironmentStaffPossibleValues: parseAndConvert(row[81]),
                        WorkplaceSupplierScoreValues: parseAndConvert(row[82]),
                        WorkplaceSupplierPossibleValues: parseAndConvert(row[83]),
                        WorkplaceStaffScoreValues: parseAndConvert(row[84]),
                        WorkplaceStaffPossibleValues: parseAndConvert(row[85]),
                        HIVSupplierScoreValues: parseAndConvert(row[86]),
                        HIVSupplierPossibleValues: parseAndConvert(row[87]),
                        HIVStaffScoreValues: parseAndConvert(row[88]),
                        HIVStaffPossibleValues: parseAndConvert(row[89]),
                        CompanyInfo: row[90],
                        CompanyFixes: row[91],
                        CompanyPositives: row[92],
                        CloutSASalesPercentage: row[93],
                        Ownership: row[94],
                        Employees: row[95]

                        

                    };
                    companies_array.push(this_company);
                }

                setCompanies(companies_array);
                setLoading(false);
            })
            .catch((error) => {
                setError(error);
                setLoading(false);
            });
    }, [GOOGLE_API_KEY, GOOGLE_SHEET, GOOGLE_SHEET_TAB]);

    

    useEffect  (() => {
        // set company to the first company in the companies array
        setCompany(companies[0]);
    }, [companies]);


    const handleChange = (event: SelectChangeEvent) => {
        // get the company with the selected company name
        let selected_company = companies.find((company) => company.Name === event.target.value);
        setCompany(selected_company);

      };

      function makeBoldBeforeColonForEachLine(value: string) {
        if (value) {
            const lines = value.split("\n");
            const newLines = lines.map((line) => {
                const index = line.indexOf(":");
                if (index > 0) {
                    return (
                        <span>
                            <b>{line.substring(0, index + 1)}</b>
                            {line.substring(index + 1)}<br />
                        </span>
                    );
                }
                return line;
            });
            return newLines;
        }
        return value;
    }


        




        return (
            <div>
                { company && (
                <Grid container 
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={2}
                >

                <Grid item xs={12}>
                    <Typography variant="h2" component="h2" color={'#000000'} marginBottom={'10px'}><b>Company Details</b></Typography>
                    {loading && <p>Loading...</p>}
                    {error && <p>{error}</p>}
                </Grid>


            <Grid item xs={12} md={6}>
                <Typography variant="h5" component="h5" color={'#000000'} marginTop={'10px'}><b>Choose</b> Year</Typography>
            </Grid>

            <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Year</InputLabel>
                <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value="1"
                label="Year"
                onChange={handleChange}
                sx={{bgcolor:"#ffffff", height: "55px" }}>
                        <MenuItem value="1">2023</MenuItem>
                </Select>
                </FormControl>
            </Grid>



            <Grid item xs={12} md={6}>
            <Typography variant="h5" component="h5" color={'#000000'} marginTop={'10px'}><b>Choose</b> Company</Typography>
            
            </Grid>

            <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Company</InputLabel>
              <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={company.Name}
              label="Company"
              onChange={handleChange}
              sx={{bgcolor:"#ffffff", height: "55px" }}
              
              >
                {/* map the company in companies create a menu item with the company name, sorted alphabetically by company.Nmae */}
                {companies.sort((a, b) => a.Name.localeCompare(b.Name)).map((company) => (
                    <MenuItem value={company.Name}>{company.Name}</MenuItem>
                ))}
                


              </Select>
            </FormControl>
            </Grid>

            <Grid item xs={12}>
                    <hr />
                </Grid>


    
                    <Grid item xs={12} md={6}>
                        <Box sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            borderRadius: 2,
                            height:"60vh",
                            }}
                        >
                        <Typography variant="h4" component="h4" color={'#000000'} marginBottom={'10px'}><b>{company.Name}</b></Typography>
                            <Typography variant="h5" component="h5" color={'#000000'} marginBottom={'10px'}><b>Size: </b>{company.Size}</Typography>
                            <Typography variant="body1" component="p">
                            <div style={{whiteSpace: "pre-wrap"}}>{makeBoldBeforeColonForEachLine(company.CompanyInfo)}</div>
                            <div style={{whiteSpace: "pre-wrap"}}><b>Ownership:</b> {company.Ownership}</div>
                            <div style={{whiteSpace: "pre-wrap"}}><b>Employees:</b> {company.Employees}</div>
                            <div style={{whiteSpace: "pre-wrap"}}><b>Percentage of {company.Name} Sales coming from DemoCo:</b> {company.CloutSASalesPercentage}</div>
                            <div style={{whiteSpace: "pre-wrap"}}><b>Percentage of DemoCo business going to {company.Name}:</b> N/A</div>

                            </Typography>


                            


                        </Box>
                    </Grid>


                    <Grid item  xs={12} md={6}>
                    <Typography variant="h4" component="h4" color={'#000000'} marginBottom={'10px'}><b>Core Compliance vs Values Alignment</b></Typography>
                        <DetailedCompanyGraph company={company} />
                    </Grid> 

                    <Grid item xs={12}>
                    <hr />
                </Grid>


                <Grid item xs={6}>
                    
                <h2>Areas for Improvement</h2>
                            <Typography variant="body1" component="p">
                            <div style={{whiteSpace: "pre-wrap"}}>{company.CompanyFixes.replaceAll("○","   ○").replaceAll("●        ","● ").replaceAll("●	","● ")}</div>
                                
                            </Typography>


                            {/* <hr></hr>
                            <h2>Positives</h2>
                            <Typography variant="body1" component="p">
                            <div style={{whiteSpace: "pre-wrap"}}>{company.CompanyPositives}</div>
                                
                            </Typography> */}


                </Grid>


                <Grid item  xs={12} md={6}>

                {company.Size !== "Sole Proprietor" && (
                    <>
                    <Typography variant="h4" component="h4" color={'#000000'} marginBottom={'10px'}><b>Supplier vs Staff Analysis</b></Typography>
                        <SupplierStaffCompanyGraph company={company} />
                        </>
                )}
                    </Grid> 




                    <Grid item  xs={12} md={12}>
                    <hr></hr>
    </Grid> 



                </Grid>





                )}
            </div>
        );
    }

export default CompanyDetails;