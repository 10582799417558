// display category graph according category model

// Path: src/Components/CategoryGraph.tsx

import React from "react";
import {Typography, Grid, Box} from '@mui/material';
import { Category } from "../Models/Category";
import { Bar } from 'react-chartjs-2';
import { Paper } from '@mui/material';
import ChartDataLabels from "chartjs-plugin-datalabels";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    LineElement,
    PointElement,
    RadialLinearScale
    } from 'chart.js';
import { Link } from "react-router-dom";

function CategoryGraph(props: {category: Category}): JSX.Element {
    
        ChartJS.register(ChartDataLabels);
    
        // Change default options for ALL charts
        ChartJS.defaults.set('plugins.datalabels', {
            color: '#ffffff',
            anchor: "end",
            align: "start"
        });
    
        return (

    
                        <Paper elevation={0} sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            borderRadius: 2,
                            height:"60vh",
                            }}
                        >
                            <Bar
                                data={{
                                    labels: props.category.Companies,
                                    datasets: [
                                        {
                                            label: 'Core Compliance',
                                            data: props.category.CoreCompliance,
                                            borderColor: '#F78F00',
                                            backgroundColor: '#F78F00',
                                            borderWidth: 1,
                                        },
                                        {
                                            label: 'Values Alignment',
                                            data: props.category.ValuesAlignment,
                                            borderColor: '#17C8D6',
                                            backgroundColor: '#17C8D6',
                                            borderWidth: 1,
                                        },
                                    ],
                                }}
                                options={

                                    // q: how do youhide the numbers at the bottom of the graph
                                    // a: https://stackoverflow.com/questions/63810098/how-to-hide-the-numbers-at-the-bottom-of-the-graph-in-chart-js

     
                                
                                    {
                                        maintainAspectRatio: false,
                                        indexAxis: 'y' as const,
                                        elements: {
                                          bar: {
                                            borderWidth: 1,
                                          },
                                        },
                                        responsive: true,
                                        plugins: {
                                    
                                          legend: {
                                            position: 'bottom' as const,
                                          },
                                          title: {
                                            display: false,
                                            text: props.category.CategoryName,
                                          },
                                          datalabels: {
                                              formatter: function(value:number) {
                                                //set the value to 1 decimal at most
                                                // return Math.round(value * 10) / 10 +'%';
                                                return Math.round(value) +'%';

                                              }
                                          }
                                        },
                                    }
                                }
                            />
                        </Paper>

        );
    }

export default CategoryGraph;

