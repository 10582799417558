import React, {useState, useEffect} from "react";
import {useParams} from "react-router-dom";
import CompanyGraph from "../Components/CompanyGraph";
import {Company} from "../Models/Company";
import {Typography, Grid, Box} from '@mui/material';
import { Category } from "../Models/Category";
import { Link } from "react-router-dom";

function Companies(): JSX.Element {

    // read an environment variable
    const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
    const GOOGLE_SHEET = process.env.REACT_APP_GOOGLE_SHEET;
    const GOOGLE_SHEET_TAB = process.env.REACT_APP_GOOGLE_SHEET_TAB;

    // get the companies data
    const [companies, setCompanies] = useState<Company[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetch(`https://sheets.googleapis.com/v4/spreadsheets/${GOOGLE_SHEET}/values/${GOOGLE_SHEET_TAB}?key=${GOOGLE_API_KEY}`)
            .then((response) => response.json())
            .then((data) => {

                // parse the data into the company model
                let companies_array: Company[] = [];
                let rows = data.values;
                for (let i = 1; i < rows.length; i++) {
                    let row = rows[i];
                    let company: Company = {
                        Name: row[0],
                        Size: row[1],
                        AssessmentType: row[2],
                        LegalCoreCompliance: row[3],
                        LegalValuesAlignment: row[4],
                        OwnershipCoreCompliance: row[5],
                        OwnershipValuesAlignment: row[6],
                        EquityCoreCompliance: row[7],
                        EquityValuesAlignment: row[8],
                        SkillsDevelopmentCoreCompliance: row[9], 
                        SkillsDevelopmentValuesAlignment: row[10], 
                        HRCoreCompliance: row[11], 
                        HRValuesAlignment: row[12],
                        SocialInvestmentCoreCompliance: row[13],
                        SocialInvestmentValuesAlignment: row[14],
                        ProcurementCoreCompliance: row[15],
                        ProcurementValuesAlignment: row[16],
                        HealthCoreCompliance: row[17],
                        HealthValuesAlignment: row[18], 
                        EnvironmentCoreCompliance: row[19], 
                        EnvironmentValuesAlignment: row[20],
                        WorkplaceCoreCompliance: row[21],
                        WorkplaceValuesAlignment: row[22],
                        HIVCoreCompliance: row[23],
                        HIVValuesAlignment: row[24],
                        CompanyInfo: row[25],
                        CompanyFixes: row[26],
                        CompanyPositives: row[27]

                    };
                    companies_array.push(company);
                }

                setCompanies(companies_array);
                setLoading(false);
            })
            .catch((error) => {
                setError(error);
                setLoading(false);
            });
    }, [GOOGLE_API_KEY, GOOGLE_SHEET, GOOGLE_SHEET_TAB]);

    return (
        <div>

            <Grid container 
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={2}>

                <Grid item xs={12}>
                    <Typography variant="h2" component="h2" color={'#000000'} marginBottom={'10px'}><b>Companies</b></Typography>
                    {loading && <p>Loading...</p>}
                    {error && <p>{error}</p>}
                </Grid>

                {companies.map((company) => (

                    <Grid item  xs={12} sm={12} md={6} lg={6} xl={4}>
                        
                        <Typography variant="h4" component="h4" color={'#000000'} marginBottom={'10px'}><b>{company.Name}</b></Typography>

                        
                        <CompanyGraph company={company} />
                    </Grid> 



                ))}
            </Grid>

        </div>
    )
};

export default Companies;