import React, {useState, useEffect} from "react";
import {useParams} from "react-router-dom";
import CategoryGraph from "../Components/CategoryGraph";
import {Company} from "../Models/Company";
import {Typography, Grid, Box} from '@mui/material';
import { Category } from "../Models/Category";
import { Paper, InputLabel, MenuItem, Button, FormControl, Dialog, DialogContent, DialogActions } from "@mui/material";
import Select, { SelectChangeEvent } from '@mui/material/Select';

function Categories(): JSX.Element {

    // read an environment variable
    const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
    const GOOGLE_SHEET = process.env.REACT_APP_GOOGLE_SHEET;
    const GOOGLE_SHEET_TAB = process.env.REACT_APP_GOOGLE_SHEET_TAB;
    const GOOGLE_SHEET_TAB2 = "ETL";

    // get the companies data
    const [categories, setCategories] = useState<Category[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [companyType, setCompanyType] = React.useState('6 or more');

    const handleChange = (event: SelectChangeEvent) => {
        setCompanyType(event.target.value);
  };

  function parseAndConvert(input: string) {
    const parsedNumber = parseInt(input, 10);
    return isNaN(parsedNumber) ? 0 : parsedNumber;
  }

    useEffect(() => {
        fetch(`https://sheets.googleapis.com/v4/spreadsheets/${GOOGLE_SHEET}/values/${GOOGLE_SHEET_TAB2}?key=${GOOGLE_API_KEY}`)
            .then((response) => response.json())
            .then((data) => {

                // parse the data into the category model
                let categories_array: Category[] = [];
                let rows = data.values;


                console.log('begin v')
                console.log(rows)
                console.log('end ^')


                console.log('begin vv')
                console.log(companyType)
                console.log('end ^^')


                //drop the first row
                rows.shift();


                // only use rows where row[0] = companyType
                rows = rows.filter((row: any) => row[0] === companyType);




                // add the following categories: Legal	Ownership	Equity	Skills Development	HR	Social Investment	Procurement	Health	Environment	Workplace	HIV 

                let legalCategory = {
                    CategoryName: "Legal",
                    // set Companies to an array of the first column
                    Companies: rows.map((row: any) => row[1]),
                    CoreCompliance : rows.map((row: any) => (parseAndConvert(row[2]) + parseAndConvert(row[4])) / (parseAndConvert(row[3]) + parseAndConvert(row[5]) ) * 100),
                    ValuesAlignment : rows.map((row: any) => (parseAndConvert(row[46]) + parseAndConvert(row[48])) / (parseAndConvert(row[47]) + parseAndConvert(row[49]) ) * 100),
                }

                let ownershipCategory = {
                    CategoryName: "Ownership",
                    // set Companies to an array of the first column
                    Companies: rows.map((row: any) => row[1]),
                    CoreCompliance : rows.map((row: any) => (parseAndConvert(row[6]) + parseAndConvert(row[8])) / (parseAndConvert(row[7]) + parseAndConvert(row[9]) ) * 100),
                    ValuesAlignment : rows.map((row: any) => (parseAndConvert(row[50]) + parseAndConvert(row[52])) / (parseAndConvert(row[51]) + parseAndConvert(row[53]) ) * 100),
                }

                let equityCategory = {
                    CategoryName: "Equity",
                    // set Companies to an array of the first column
                    Companies: rows.map((row: any) => row[1]),
                    CoreCompliance : rows.map((row: any) => (parseAndConvert(row[10]) + parseAndConvert(row[12])) / (parseAndConvert(row[11]) + parseAndConvert(row[13]) ) * 100),
                    ValuesAlignment : rows.map((row: any) => (parseAndConvert(row[54]) + parseAndConvert(row[56])) / (parseAndConvert(row[55]) + parseAndConvert(row[57]) ) * 100),
                }

                let skillsCategory = {
                    CategoryName: "Skills Development",
                    // set Companies to an array of the first column
                    Companies: rows.map((row: any) => row[1]),
                    CoreCompliance : rows.map((row: any) => (parseAndConvert(row[14]) + parseAndConvert(row[16])) / (parseAndConvert(row[15]) + parseAndConvert(row[17]) ) * 100),
                    ValuesAlignment : rows.map((row: any) => (parseAndConvert(row[58]) + parseAndConvert(row[60])) / (parseAndConvert(row[59]) + parseAndConvert(row[61]) ) * 100),
                }

                let hrCategory = {
                    CategoryName: "HR",
                    // set Companies to an array of the first column
                    Companies: rows.map((row: any) => row[1]),
                    CoreCompliance : rows.map((row: any) => (parseAndConvert(row[18]) + parseAndConvert(row[20])) / (parseAndConvert(row[19]) + parseAndConvert(row[21]) ) * 100),
                    ValuesAlignment : rows.map((row: any) => (parseAndConvert(row[62]) + parseAndConvert(row[64])) / (parseAndConvert(row[63]) + parseAndConvert(row[65]) ) * 100),
                }

                let socialCategory = {
                    CategoryName: "Social Investment",
                    // set Companies to an array of the first column
                    Companies: rows.map((row: any) => row[1]),
                    CoreCompliance : rows.map((row: any) => (parseAndConvert(row[22]) + parseAndConvert(row[24])) / (parseAndConvert(row[23]) + parseAndConvert(row[25]) ) * 100),
                    ValuesAlignment : rows.map((row: any) => (parseAndConvert(row[66]) + parseAndConvert(row[68])) / (parseAndConvert(row[67]) + parseAndConvert(row[69]) ) * 100),
                }

                let procurementCategory = {
                    CategoryName: "Procurement",
                    // set Companies to an array of the first column
                    Companies: rows.map((row: any) => row[1]),
                    CoreCompliance : rows.map((row: any) => (parseAndConvert(row[26]) + parseAndConvert(row[28])) / (parseAndConvert(row[27]) + parseAndConvert(row[29]) ) * 100),
                    ValuesAlignment : rows.map((row: any) => (parseAndConvert(row[70]) + parseAndConvert(row[72])) / (parseAndConvert(row[71]) + parseAndConvert(row[73]) ) * 100),
                }

                let healthCategory = {
                    CategoryName: "Health",
                    // set Companies to an array of the first column
                    Companies: rows.map((row: any) => row[1]),
                    CoreCompliance : rows.map((row: any) => (parseAndConvert(row[30]) + parseAndConvert(row[32])) / (parseAndConvert(row[31]) + parseAndConvert(row[33]) ) * 100),
                    ValuesAlignment : rows.map((row: any) => (parseAndConvert(row[74]) + parseAndConvert(row[76])) / (parseAndConvert(row[75]) + parseAndConvert(row[77]) ) * 100),
                }

                let environmentCategory = {
                    CategoryName: "Environment",
                    // set Companies to an array of the first column
                    Companies: rows.map((row: any) => row[1]),
                    CoreCompliance : rows.map((row: any) => (parseAndConvert(row[34]) + parseAndConvert(row[36])) / (parseAndConvert(row[35]) + parseAndConvert(row[37]) ) * 100),
                    ValuesAlignment : rows.map((row: any) => (parseAndConvert(row[78]) + parseAndConvert(row[80])) / (parseAndConvert(row[79]) + parseAndConvert(row[81]) ) * 100),
                }

                let workplaceCategory = {
                    CategoryName: "Workplace",
                    // set Companies to an array of the first column
                    Companies: rows.map((row: any) => row[1]),
                    CoreCompliance : rows.map((row: any) => (parseAndConvert(row[38]) + parseAndConvert(row[40])) / (parseAndConvert(row[39]) + parseAndConvert(row[41]) ) * 100),
                    ValuesAlignment : rows.map((row: any) => (parseAndConvert(row[82]) + parseAndConvert(row[84])) / (parseAndConvert(row[83]) + parseAndConvert(row[85]) ) * 100),
                }

                let hivCategory = {
                    CategoryName: "HIV",
                    // set Companies to an array of the first column
                    Companies: rows.map((row: any) => row[1]),
                    CoreCompliance : rows.map((row: any) => (parseAndConvert(row[42]) + parseAndConvert(row[44])) / (parseAndConvert(row[43]) + parseAndConvert(row[45]) ) * 100),
                    ValuesAlignment : rows.map((row: any) => (parseAndConvert(row[86]) + parseAndConvert(row[88])) / (parseAndConvert(row[87]) + parseAndConvert(row[89]) ) * 100),
                }

                categories_array.push(legalCategory);
                categories_array.push(ownershipCategory);
                categories_array.push(equityCategory);
                categories_array.push(skillsCategory);
                categories_array.push(hrCategory);
                categories_array.push(socialCategory);
                categories_array.push(procurementCategory);
                categories_array.push(healthCategory);
                categories_array.push(environmentCategory);
                categories_array.push(workplaceCategory);
                categories_array.push(hivCategory);

                



                setCategories(categories_array);
                setLoading(false);
            })
            .catch((error) => {
                setError(error);
                setLoading(false);
            });
    }, [GOOGLE_API_KEY, GOOGLE_SHEET, GOOGLE_SHEET_TAB, companyType]);

    return (
        <div>

            <Grid container 
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={2}>

                <Grid item xs={12}>
                    <Typography variant="h2" component="h2" color={'#000000'} marginBottom={'10px'}><b>Ethical Categories</b></Typography>
                    {loading && <p>Loading...</p>}
                    {error && <p>{error}</p>}
                </Grid>

                <Grid item xs={12} md={6}>
                <Typography variant="h5" component="h5" color={'#000000'} marginTop={'10px'}><b>Choose</b> Year</Typography>
            </Grid>

            <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Year</InputLabel>
                <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value="1"
                label="Year"
                onChange={handleChange}
                sx={{bgcolor:"#ffffff", height: "55px" }}>
                        <MenuItem value="1">2023</MenuItem>
                </Select>
                </FormControl>
            </Grid>


            <Grid item xs={12} md={6}>
            <Typography variant="h5" component="h5" color={'#000000'} marginTop={'10px'}><b>Choose</b> Company Size</Typography>
            </Grid>

            <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Company Size</InputLabel>
              <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={companyType}
              label="Company Size"
              onChange={handleChange}
              sx={{bgcolor:"#ffffff", height: "55px" }}
              
              >

                    <MenuItem value="Sole Proprietor">Sole Proprietor</MenuItem>
                    <MenuItem value="5 and under">5 and under</MenuItem>
                    <MenuItem value="6 or more">6 or more</MenuItem>


              </Select>
            </FormControl>
            </Grid>

            <Grid item xs={12}>
                    <hr />
                </Grid>


                {categories.map((category) => (

                    <Grid item  xs={12} sm={12} md={6} lg={6} xl={4}>
                        <Typography variant="h4" component="h4" color={'#000000'} marginBottom={'10px'}><b>{category.CategoryName}</b></Typography>
                        <CategoryGraph category={category} />
                    </Grid> 

                ))}
            </Grid>

        </div>
    )
};

export default Categories;