import React, { useEffect, useState } from "react";
import { Grid, Card, CardMedia, Typography} from '@mui/material';
import { Link } from "react-router-dom";

function Project(): JSX.Element {

    const [portalUsers, setPortalUsers] = useState([]);

    const fetchPortalUsers = async () => {
      const response = await fetch("http://localhost:8000/not_secure")
      const portalUsers = await response.json()
      console.log(portalUsers)
      setPortalUsers(portalUsers)
    }

    useEffect(() => {
        fetchPortalUsers()
    }, [])

    return (
        <div>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={5}
            >                
                <p>
                {portalUsers}
                </p>

                <Grid item xs={12} md={8} lg={12}>
                    <Typography variant="h2" component="h2" color={'#000000'} marginBottom={'20px'}>
                    <b>DemoCo</b> Ethical Supplier Assessment</Typography>

                    {/* <Grid container 
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={1}
                    sx={{ marginBottom: "20px" }}>

                        <Grid item md={4}>
                            <img width={170} height={190} src={"/Data.png"} />
                        </Grid>
                
                        <Grid item md={4}>
                        <img width={170} height={190} src={"/Analysis.png"} />
                        </Grid>

                        <Grid item md={4}>
                        <img width={170} height={190} src={"/Visualisations.png"} />
                        </Grid>

                    </Grid> */}

                </Grid>

                <Grid item lg={8}>



<p>

Welcome to the DemoCo ethical supplier portal.   This portal’s core objectives are:  <br /><br />

<ul>
<li><b>Legal and Values alignment: </b> to gain a better understanding of how its portfolio of suppliers are adhering to South African legislation and align with DemoCo’s value system.
</li><li><b>Risk Mitigation: </b> clarify the potential legal risks DemoCo may confront by expanding the market access for the businesses involved.  
</li><li><b>Development Baseline: </b> establish a baseline, specifically related to legal compliance and DemoCo’s values for all the businesses active on the portal.  This baseline functions as a tool for creating specific areas of improvement for each business as they mature, grow and expand their customer base.  <br /><br/>

The process, scoring system, and business analysis reflected on this portal was conducted by Impact Amplifier, an independent consultancy with domain expertise in developing ethical supply chains (<a href="https://www.impactamplifier.co.za/" target="_blank">www.impactamplifier.co.za</a>). 
</li>
</ul>

<h1>Process</h1>

The process for establishing a legal and ethical baseline with DemoCo’s existing suppliers involved collecting data from both the suppliers and their employees.  The suppliers were sent a self-assessment questionnaire, while the staff were interviewed directly.  <br /><br />

The combination of these two data sets, was scored using a tool that flags DemoCo’s risks, but also areas which, DemoCo’s wants its suppliers to improve on, based on its values system.   This information allows DemoCo to establish growth expectation, monitor progress and determine how it has influenced improvements regarding a range of core metrics, like Health and Safety or a Living Wage for example.   
Each business was assessed regarding the following 12 areas.   <br /><br />

<h3>Category</h3>
<ul>
<li><b>
Legal and General: </b> addresses issues of registrations, licences, insurance
</li><li><b>Labour Standards: </b> contracted terms, wage levels, hours, overtime, freedom of association
</li><li><b>Human Resource Practice: </b> policies, general relations between management and staff, performance management, harassment, incentives
</li><li><b>Skills Development: </b> plan in place, training offered, fair allocation of what is offered, annual spend
</li><li><b>Employment Equity: </b> plan in place, wage and responsibility equity across race and gender 
</li><li><b>Ownership and Control: </b> based on race, gender, staff
</li><li><b>Procurement: </b> preferential policies, local economic development, small business
</li><li><b>Social/Community Investment: </b> annual spend, issues of focus, alternative forms of community investment
</li><li><b>Environmental & Conservation: </b> environmental impact assessment completed, conservation plans in place, staff involvement
</li><li><b>Health and Safety: </b> legal compliance, management commitment, employee risks
</li><li><b>Workplace Culture: </b> communications, staff role in decision making, discrimination policy, codes of conduct
</li><li><b>Personal Well Being: </b> HIV policies and approach, wellness education, programmatic support
</li>
</ul>

These 12 focus areas were scored across two dimensions: 
<ul>
<li><b>Core Compliance: </b> this is principally related to the business’ legal compliance, but it is also related to basic responsible business practices; and 
</li><li><b>Values Alignment: </b> reviews how closely the business aligns to what DemoCo has self-defined as important and the standards of business practices it aspires to. 
</li>
</ul>

To finalise the scoring tool, required further refinement by the DemoCo team.  This relates to two distinct issues:

<ul>
<li><b>Business Size: </b> some of the issues above are not appropriate or should be considered differently for companies with less than six employees.  The scoring tools were therefore adapted for three separate sizes: sole proprietors; businesses with 1-5 employees; and businesses with six or more employees.  
</li><li><b>Values Alignment: </b> 30-40% of the overall scores are based on how DemoCo has defined what is most important to it as a business.  While the categories being scored was not affected, these decisions did affect how different aspects of the values section of the scoring was weighted.
</li>
</ul>

<h3>Site Navigation</h3>

This site can be navigated by comparing all the businesses on the site, reviewing them by size, locations, type and individually.   The navigation page explains further various ways to review and consider the data on this site.   

</p>

</Grid>

<Grid item lg={4}>

 {/* show the mag1.jpeg image in the browser, make the width 200px */}
 <img width={300} src={"/img1.jpg"} /> <br /><br />
 <img width={300} src={"/img2.jpg"} /> <br /><br />
 <img width={300} src={"/img3.jpg"} /> <br /><br />



</Grid>



                {/* <Grid item xs={12} md={6} lg={5}>
                
                    <Grid container 
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={5}>

                        <Grid item xs={12}>
                        <Typography variant="h4" component="h4" color={'#000000'} ></Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <img width={250} src={"/nandos_logo.svg"} />
                        </Grid>

                        <Grid item xs={12}>
                        <Card sx={{ maxWidth: 500 }}>
                            <CardMedia
                                component="img"
                                height="200"
                                image="/im1.jpg"
                            />
                            </Card>
                        </Grid>

                        <Grid item xs={12}>
                        <Card sx={{ maxWidth: 500 }}>
                            <CardMedia
                                component="img"
                                height="200"
                                image="/im2.png"
                            />
                            </Card>
                        </Grid>

                        <Grid item xs={12}>
                        <Card sx={{ maxWidth: 500 }}>
                            <CardMedia
                                component="img"
                                height="200"
                                image="/im4.jpg"
                            />
                            </Card>
                        </Grid>

                    </Grid>
                </Grid> */}
            </Grid>
        </div>
    );
  }
  
  export default Project;