// component to display the CompanyInfo and CompanyFixes fields

import React from "react";
import { DetailedCompany } from "../Models/DetailedCompany";
import {useParams} from "react-router-dom";
import { useState, useEffect } from "react";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Paper, Grid, InputLabel, MenuItem, Button, FormControl, Box, Typography, Dialog, DialogContent, DialogActions } from "@mui/material";
import CompanyGraph from "../Components/CompanyGraph";
import DetailedCompanyGraph from "../Components/DetailedCompanyGraph";
import SupplierStaffCompanyGraph from "../Components/SupplierStaffCompanyGraph";


function Comparison(): JSX.Element {

    // read an environment variable
    const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
    const GOOGLE_SHEET = process.env.REACT_APP_GOOGLE_SHEET;
    const GOOGLE_SHEET_TAB = process.env.REACT_APP_GOOGLE_SHEET_TAB;
    const GOOGLE_SHEET_TAB2 = 'ETL';
    
    // get the companies data
    const [companies, setCompanies] = useState<DetailedCompany[]>([]);
    const [company, setCompany] = useState<DetailedCompany>();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const [averageCompany, setAverageCompany] = useState<DetailedCompany>();

    function parseAndConvert(input: string) {
        const parsedNumber = parseInt(input, 10);
        return isNaN(parsedNumber) ? 0 : parsedNumber;
      }

    useEffect(() => {
        fetch(`https://sheets.googleapis.com/v4/spreadsheets/${GOOGLE_SHEET}/values/${GOOGLE_SHEET_TAB2}?key=${GOOGLE_API_KEY}`)
            .then((response) => response.json())
            .then((data) => {

                // parse the data into the company model
                let companies_array: DetailedCompany[] = [];
                let rows = data.values;
                for (let i = 1; i < rows.length; i++) {
                    let row = rows[i];
                    let this_company: DetailedCompany = {
                        Size: row[0],
                        Name: row[1],
                        LegalSupplierScoreCore: parseAndConvert(row[2]),
                        LegalSupplierPossibleCore: parseAndConvert(row[3]),
                        LegalStaffScoreCore: parseAndConvert(row[4]),
                        LegalStaffPossibleCore: parseAndConvert(row[5]),
                        OwnershipSupplierScoreCore: parseAndConvert(row[6]),
                        OwnershipSupplierPossibleCore: parseAndConvert(row[7]),
                        OwnershipStaffScoreCore: parseAndConvert(row[8]),
                        OwnershipStaffPossibleCore: parseAndConvert(row[9]),
                        EquitySupplierScoreCore: parseAndConvert(row[10]),
                        EquitySupplierPossibleCore: parseAndConvert(row[11]),
                        EquityStaffScoreCore: parseAndConvert(row[12]),
                        EquityStaffPossibleCore: parseAndConvert(row[13]),
                        SkillsDevelopmentSupplierScoreCore: parseAndConvert(row[14]),
                        SkillsDevelopmentSupplierPossibleCore: parseAndConvert(row[15]),
                        SkillsDevelopmentStaffScoreCore: parseAndConvert(row[16]),
                        SkillsDevelopmentStaffPossibleCore: parseAndConvert(row[17]),
                        HRSupplierScoreCore: parseAndConvert(row[18]),
                        HRSupplierPossibleCore: parseAndConvert(row[19]),
                        HRStaffScoreCore: parseAndConvert(row[20]),
                        HRStaffPossibleCore: parseAndConvert(row[21]),
                        SocialInvestmentSupplierScoreCore: parseAndConvert(row[22]),
                        SocialInvestmentSupplierPossibleCore: parseAndConvert(row[23]),
                        SocialInvestmentStaffScoreCore: parseAndConvert(row[24]),
                        SocialInvestmentStaffPossibleCore: parseAndConvert(row[25]),
                        ProcurementSupplierScoreCore: parseAndConvert(row[26]),
                        ProcurementSupplierPossibleCore: parseAndConvert(row[27]),
                        ProcurementStaffScoreCore: parseAndConvert(row[28]),
                        ProcurementStaffPossibleCore: parseAndConvert(row[29]),
                        HealthSupplierScoreCore: parseAndConvert(row[30]),
                        HealthSupplierPossibleCore: parseAndConvert(row[31]),
                        HealthStaffScoreCore: parseAndConvert(row[32]),
                        HealthStaffPossibleCore: parseAndConvert(row[33]),
                        EnvironmentSupplierScoreCore: parseAndConvert(row[34]),
                        EnvironmentSupplierPossibleCore: parseAndConvert(row[35]),
                        EnvironmentStaffScoreCore: parseAndConvert(row[36]),
                        EnvironmentStaffPossibleCore: parseAndConvert(row[37]),
                        WorkplaceSupplierScoreCore: parseAndConvert(row[38]),
                        WorkplaceSupplierPossibleCore: parseAndConvert(row[39]),
                        WorkplaceStaffScoreCore: parseAndConvert(row[40]),
                        WorkplaceStaffPossibleCore: parseAndConvert(row[41]),
                        HIVSupplierScoreCore: parseAndConvert(row[42]),
                        HIVSupplierPossibleCore: parseAndConvert(row[43]),
                        HIVStaffScoreCore: parseAndConvert(row[44]),
                        HIVStaffPossibleCore: parseAndConvert(row[45]),
                        LegalSupplierScoreValues: parseAndConvert(row[46]),
                        LegalSupplierPossibleValues: parseAndConvert(row[47]),
                        LegalStaffScoreValues: parseAndConvert(row[48]),
                        LegalStaffPossibleValues: parseAndConvert(row[49]),
                        OwnershipSupplierScoreValues: parseAndConvert(row[50]),
                        OwnershipSupplierPossibleValues: parseAndConvert(row[51]),
                        OwnershipStaffScoreValues: parseAndConvert(row[52]),
                        OwnershipStaffPossibleValues: parseAndConvert(row[53]),
                        EquitySupplierScoreValues: parseAndConvert(row[54]),
                        EquitySupplierPossibleValues: parseAndConvert(row[55]),
                        EquityStaffScoreValues: parseAndConvert(row[56]),
                        EquityStaffPossibleValues: parseAndConvert(row[57]),
                        SkillsDevelopmentSupplierScoreValues: parseAndConvert(row[58]),
                        SkillsDevelopmentSupplierPossibleValues: parseAndConvert(row[59]),
                        SkillsDevelopmentStaffScoreValues: parseAndConvert(row[60]),
                        SkillsDevelopmentStaffPossibleValues: parseAndConvert(row[61]),
                        HRSupplierScoreValues: parseAndConvert(row[62]),
                        HRSupplierPossibleValues: parseAndConvert(row[63]),
                        HRStaffScoreValues: parseAndConvert(row[64]),
                        HRStaffPossibleValues: parseAndConvert(row[65]),
                        SocialInvestmentSupplierScoreValues: parseAndConvert(row[66]),
                        SocialInvestmentSupplierPossibleValues: parseAndConvert(row[67]),
                        SocialInvestmentStaffScoreValues: parseAndConvert(row[68]),
                        SocialInvestmentStaffPossibleValues: parseAndConvert(row[69]),
                        ProcurementSupplierScoreValues: parseAndConvert(row[70]),
                        ProcurementSupplierPossibleValues: parseAndConvert(row[71]),
                        ProcurementStaffScoreValues: parseAndConvert(row[72]),
                        ProcurementStaffPossibleValues: parseAndConvert(row[73]),
                        HealthSupplierScoreValues: parseAndConvert(row[74]),
                        HealthSupplierPossibleValues: parseAndConvert(row[75]),
                        HealthStaffScoreValues: parseAndConvert(row[76]),
                        HealthStaffPossibleValues: parseAndConvert(row[77]),
                        EnvironmentSupplierScoreValues: parseAndConvert(row[78]),
                        EnvironmentSupplierPossibleValues: parseAndConvert(row[79]),
                        EnvironmentStaffScoreValues: parseAndConvert(row[80]),
                        EnvironmentStaffPossibleValues: parseAndConvert(row[81]),
                        WorkplaceSupplierScoreValues: parseAndConvert(row[82]),
                        WorkplaceSupplierPossibleValues: parseAndConvert(row[83]),
                        WorkplaceStaffScoreValues: parseAndConvert(row[84]),
                        WorkplaceStaffPossibleValues: parseAndConvert(row[85]),
                        HIVSupplierScoreValues: parseAndConvert(row[86]),
                        HIVSupplierPossibleValues: parseAndConvert(row[87]),
                        HIVStaffScoreValues: parseAndConvert(row[88]),
                        HIVStaffPossibleValues: parseAndConvert(row[89]),
                        CompanyInfo: row[90],
                        CompanyFixes: row[91],
                        CompanyPositives: row[92],
                        CloutSASalesPercentage: row[93],
                        Ownership: row[94],
                        Employees: row[95]

                        

                    };
                    companies_array.push(this_company);
                }

                setCompanies(companies_array);
                setLoading(false);
            })
            .catch((error) => {
                setError(error);
                setLoading(false);
            });
    }, [GOOGLE_API_KEY, GOOGLE_SHEET, GOOGLE_SHEET_TAB]);

    

    useEffect  (() => {
        // set company to the first company in the companies array
        setCompany(companies[0]);
    }, [companies]);

    

    useEffect  (() => {
        // get the Size of the company
        if (company) {
            let company_size = company.Size;
            // get the companies with the same size
            let companies_with_same_size = companies.filter((company) => company.Size === company_size);


        const this_averageCompany = {
            Name: "Average per Category",
            Size: "All",
            AssessmentType: "All",

            LegalSupplierScoreCore: companies_with_same_size.map((company: DetailedCompany) => company.LegalSupplierScoreCore).reduce((a: number, b: number) => a + b, 0),
            LegalSupplierPossibleCore: companies_with_same_size.map((company: DetailedCompany) => company.LegalSupplierPossibleCore).reduce((a: number, b: number) => a + b, 0),
            LegalStaffScoreCore: companies_with_same_size.map((company: DetailedCompany) => company.LegalStaffScoreCore).reduce((a: number, b: number) => a + b, 0),
            LegalStaffPossibleCore: companies_with_same_size.map((company: DetailedCompany) => company.LegalStaffPossibleCore).reduce((a: number, b: number) => a + b, 0),
            OwnershipSupplierScoreCore: companies_with_same_size.map((company: DetailedCompany) => company.OwnershipSupplierScoreCore).reduce((a: number, b: number) => a + b, 0),
            OwnershipSupplierPossibleCore: companies_with_same_size.map((company: DetailedCompany) => company.OwnershipSupplierPossibleCore).reduce((a: number, b: number) => a + b, 0),
            OwnershipStaffScoreCore: companies_with_same_size.map((company: DetailedCompany) => company.OwnershipStaffScoreCore).reduce((a: number, b: number) => a + b, 0),
            OwnershipStaffPossibleCore: companies_with_same_size.map((company: DetailedCompany) => company.OwnershipStaffPossibleCore).reduce((a: number, b: number) => a + b, 0),
            EquitySupplierScoreCore: companies_with_same_size.map((company: DetailedCompany) => company.EquitySupplierScoreCore).reduce((a: number, b: number) => a + b, 0),
            EquitySupplierPossibleCore: companies_with_same_size.map((company: DetailedCompany) => company.EquitySupplierPossibleCore).reduce((a: number, b: number) => a + b, 0),
            EquityStaffScoreCore: companies_with_same_size.map((company: DetailedCompany) => company.EquityStaffScoreCore).reduce((a: number, b: number) => a + b, 0),
            EquityStaffPossibleCore: companies_with_same_size.map((company: DetailedCompany) => company.EquityStaffPossibleCore).reduce((a: number, b: number) => a + b, 0),
            SkillsDevelopmentSupplierScoreCore: companies_with_same_size.map((company: DetailedCompany) => company.SkillsDevelopmentSupplierScoreCore).reduce((a: number, b: number) => a + b, 0),
            SkillsDevelopmentSupplierPossibleCore: companies_with_same_size.map((company: DetailedCompany) => company.SkillsDevelopmentSupplierPossibleCore).reduce((a: number, b: number) => a + b, 0),
            SkillsDevelopmentStaffScoreCore: companies_with_same_size.map((company: DetailedCompany) => company.SkillsDevelopmentStaffScoreCore).reduce((a: number, b: number) => a + b, 0),
            SkillsDevelopmentStaffPossibleCore: companies_with_same_size.map((company: DetailedCompany) => company.SkillsDevelopmentStaffPossibleCore).reduce((a: number, b: number) => a + b, 0),
            HRSupplierScoreCore: companies_with_same_size.map((company: DetailedCompany) => company.HRSupplierScoreCore).reduce((a: number, b: number) => a + b, 0),
            HRSupplierPossibleCore: companies_with_same_size.map((company: DetailedCompany) => company.HRSupplierPossibleCore).reduce((a: number, b: number) => a + b, 0),
            HRStaffScoreCore: companies_with_same_size.map((company: DetailedCompany) => company.HRStaffScoreCore).reduce((a: number, b: number) => a + b, 0),
            HRStaffPossibleCore: companies_with_same_size.map((company: DetailedCompany) => company.HRStaffPossibleCore).reduce((a: number, b: number) => a + b, 0),
            SocialInvestmentSupplierScoreCore: companies_with_same_size.map((company: DetailedCompany) => company.SocialInvestmentSupplierScoreCore).reduce((a: number, b: number) => a + b, 0),
            SocialInvestmentSupplierPossibleCore: companies_with_same_size.map((company: DetailedCompany) => company.SocialInvestmentSupplierPossibleCore).reduce((a: number, b: number) => a + b, 0),
            SocialInvestmentStaffScoreCore: companies_with_same_size.map((company: DetailedCompany) => company.SocialInvestmentStaffScoreCore).reduce((a: number, b: number) => a + b, 0),
            SocialInvestmentStaffPossibleCore: companies_with_same_size.map((company: DetailedCompany) => company.SocialInvestmentStaffPossibleCore).reduce((a: number, b: number) => a + b, 0),
            ProcurementSupplierScoreCore: companies_with_same_size.map((company: DetailedCompany) => company.ProcurementSupplierScoreCore).reduce((a: number, b: number) => a + b, 0),
            ProcurementSupplierPossibleCore: companies_with_same_size.map((company: DetailedCompany) => company.ProcurementSupplierPossibleCore).reduce((a: number, b: number) => a + b, 0),
            ProcurementStaffScoreCore: companies_with_same_size.map((company: DetailedCompany) => company.ProcurementStaffScoreCore).reduce((a: number, b: number) => a + b, 0),
            ProcurementStaffPossibleCore: companies_with_same_size.map((company: DetailedCompany) => company.ProcurementStaffPossibleCore).reduce((a: number, b: number) => a + b, 0),
            HealthSupplierScoreCore: companies_with_same_size.map((company: DetailedCompany) => company.HealthSupplierScoreCore).reduce((a: number, b: number) => a + b, 0),
            HealthSupplierPossibleCore: companies_with_same_size.map((company: DetailedCompany) => company.HealthSupplierPossibleCore).reduce((a: number, b: number) => a + b, 0),
            HealthStaffScoreCore: companies_with_same_size.map((company: DetailedCompany) => company.HealthStaffScoreCore).reduce((a: number, b: number) => a + b, 0),
            HealthStaffPossibleCore: companies_with_same_size.map((company: DetailedCompany) => company.HealthStaffPossibleCore).reduce((a: number, b: number) => a + b, 0),
            EnvironmentSupplierScoreCore: companies_with_same_size.map((company: DetailedCompany) => company.EnvironmentSupplierScoreCore).reduce((a: number, b: number) => a + b, 0),
            EnvironmentSupplierPossibleCore: companies_with_same_size.map((company: DetailedCompany) => company.EnvironmentSupplierPossibleCore).reduce((a: number, b: number) => a + b, 0),
            EnvironmentStaffScoreCore: companies_with_same_size.map((company: DetailedCompany) => company.EnvironmentStaffScoreCore).reduce((a: number, b: number) => a + b, 0),
            EnvironmentStaffPossibleCore: companies_with_same_size.map((company: DetailedCompany) => company.EnvironmentStaffPossibleCore).reduce((a: number, b: number) => a + b, 0),
            WorkplaceSupplierScoreCore: companies_with_same_size.map((company: DetailedCompany) => company.WorkplaceSupplierScoreCore).reduce((a: number, b: number) => a + b, 0),
            WorkplaceSupplierPossibleCore: companies_with_same_size.map((company: DetailedCompany) => company.WorkplaceSupplierPossibleCore).reduce((a: number, b: number) => a + b, 0),
            WorkplaceStaffScoreCore: companies_with_same_size.map((company: DetailedCompany) => company.WorkplaceStaffScoreCore).reduce((a: number, b: number) => a + b, 0),
            WorkplaceStaffPossibleCore: companies_with_same_size.map((company: DetailedCompany) => company.WorkplaceStaffPossibleCore).reduce((a: number, b: number) => a + b, 0),
            HIVSupplierScoreCore: companies_with_same_size.map((company: DetailedCompany) => company.HIVSupplierScoreCore).reduce((a: number, b: number) => a + b, 0),
            HIVSupplierPossibleCore: companies_with_same_size.map((company: DetailedCompany) => company.HIVSupplierPossibleCore).reduce((a: number, b: number) => a + b, 0),
            HIVStaffScoreCore: companies_with_same_size.map((company: DetailedCompany) => company.HIVStaffScoreCore).reduce((a: number, b: number) => a + b, 0),
            HIVStaffPossibleCore: companies_with_same_size.map((company: DetailedCompany) => company.HIVStaffPossibleCore).reduce((a: number, b: number) => a + b, 0),



            LegalSupplierScoreValues: companies_with_same_size.map((company: DetailedCompany) => company.LegalSupplierScoreValues).reduce((a: number, b: number) => a + b, 0),
            LegalSupplierPossibleValues: companies_with_same_size.map((company: DetailedCompany) => company.LegalSupplierPossibleValues).reduce((a: number, b: number) => a + b, 0),
            LegalStaffScoreValues: companies_with_same_size.map((company: DetailedCompany) => company.LegalStaffScoreValues).reduce((a: number, b: number) => a + b, 0),
            LegalStaffPossibleValues: companies_with_same_size.map((company: DetailedCompany) => company.LegalStaffPossibleValues).reduce((a: number, b: number) => a + b, 0),
            OwnershipSupplierScoreValues: companies_with_same_size.map((company: DetailedCompany) => company.OwnershipSupplierScoreValues).reduce((a: number, b: number) => a + b, 0),
            OwnershipSupplierPossibleValues: companies_with_same_size.map((company: DetailedCompany) => company.OwnershipSupplierPossibleValues).reduce((a: number, b: number) => a + b, 0),
            OwnershipStaffScoreValues: companies_with_same_size.map((company: DetailedCompany) => company.OwnershipStaffScoreValues).reduce((a: number, b: number) => a + b, 0),
            OwnershipStaffPossibleValues: companies_with_same_size.map((company: DetailedCompany) => company.OwnershipStaffPossibleValues).reduce((a: number, b: number) => a + b, 0),
            EquitySupplierScoreValues: companies_with_same_size.map((company: DetailedCompany) => company.EquitySupplierScoreValues).reduce((a: number, b: number) => a + b, 0),
            EquitySupplierPossibleValues: companies_with_same_size.map((company: DetailedCompany) => company.EquitySupplierPossibleValues).reduce((a: number, b: number) => a + b, 0),
            EquityStaffScoreValues: companies_with_same_size.map((company: DetailedCompany) => company.EquityStaffScoreValues).reduce((a: number, b: number) => a + b, 0),
            EquityStaffPossibleValues: companies_with_same_size.map((company: DetailedCompany) => company.EquityStaffPossibleValues).reduce((a: number, b: number) => a + b, 0),
            SkillsDevelopmentSupplierScoreValues: companies_with_same_size.map((company: DetailedCompany) => company.SkillsDevelopmentSupplierScoreValues).reduce((a: number, b: number) => a + b, 0),
            SkillsDevelopmentSupplierPossibleValues: companies_with_same_size.map((company: DetailedCompany) => company.SkillsDevelopmentSupplierPossibleValues).reduce((a: number, b: number) => a + b, 0),
            SkillsDevelopmentStaffScoreValues: companies_with_same_size.map((company: DetailedCompany) => company.SkillsDevelopmentStaffScoreValues).reduce((a: number, b: number) => a + b, 0),
            SkillsDevelopmentStaffPossibleValues: companies_with_same_size.map((company: DetailedCompany) => company.SkillsDevelopmentStaffPossibleValues).reduce((a: number, b: number) => a + b, 0),
            HRSupplierScoreValues: companies_with_same_size.map((company: DetailedCompany) => company.HRSupplierScoreValues).reduce((a: number, b: number) => a + b, 0),
            HRSupplierPossibleValues: companies_with_same_size.map((company: DetailedCompany) => company.HRSupplierPossibleValues).reduce((a: number, b: number) => a + b, 0),
            HRStaffScoreValues: companies_with_same_size.map((company: DetailedCompany) => company.HRStaffScoreValues).reduce((a: number, b: number) => a + b, 0),
            HRStaffPossibleValues: companies_with_same_size.map((company: DetailedCompany) => company.HRStaffPossibleValues).reduce((a: number, b: number) => a + b, 0),
            SocialInvestmentSupplierScoreValues: companies_with_same_size.map((company: DetailedCompany) => company.SocialInvestmentSupplierScoreValues).reduce((a: number, b: number) => a + b, 0),
            SocialInvestmentSupplierPossibleValues: companies_with_same_size.map((company: DetailedCompany) => company.SocialInvestmentSupplierPossibleValues).reduce((a: number, b: number) => a + b, 0),
            SocialInvestmentStaffScoreValues: companies_with_same_size.map((company: DetailedCompany) => company.SocialInvestmentStaffScoreValues).reduce((a: number, b: number) => a + b, 0),
            SocialInvestmentStaffPossibleValues: companies_with_same_size.map((company: DetailedCompany) => company.SocialInvestmentStaffPossibleValues).reduce((a: number, b: number) => a + b, 0),
            ProcurementSupplierScoreValues: companies_with_same_size.map((company: DetailedCompany) => company.ProcurementSupplierScoreValues).reduce((a: number, b: number) => a + b, 0),
            ProcurementSupplierPossibleValues: companies_with_same_size.map((company: DetailedCompany) => company.ProcurementSupplierPossibleValues).reduce((a: number, b: number) => a + b, 0),
            ProcurementStaffScoreValues: companies_with_same_size.map((company: DetailedCompany) => company.ProcurementStaffScoreValues).reduce((a: number, b: number) => a + b, 0),
            ProcurementStaffPossibleValues: companies_with_same_size.map((company: DetailedCompany) => company.ProcurementStaffPossibleValues).reduce((a: number, b: number) => a + b, 0),
            HealthSupplierScoreValues: companies_with_same_size.map((company: DetailedCompany) => company.HealthSupplierScoreValues).reduce((a: number, b: number) => a + b, 0),
            HealthSupplierPossibleValues: companies_with_same_size.map((company: DetailedCompany) => company.HealthSupplierPossibleValues).reduce((a: number, b: number) => a + b, 0),
            HealthStaffScoreValues: companies_with_same_size.map((company: DetailedCompany) => company.HealthStaffScoreValues).reduce((a: number, b: number) => a + b, 0),
            HealthStaffPossibleValues: companies_with_same_size.map((company: DetailedCompany) => company.HealthStaffPossibleValues).reduce((a: number, b: number) => a + b, 0),
            EnvironmentSupplierScoreValues: companies_with_same_size.map((company: DetailedCompany) => company.EnvironmentSupplierScoreValues).reduce((a: number, b: number) => a + b, 0),
            EnvironmentSupplierPossibleValues: companies_with_same_size.map((company: DetailedCompany) => company.EnvironmentSupplierPossibleValues).reduce((a: number, b: number) => a + b, 0),
            EnvironmentStaffScoreValues: companies_with_same_size.map((company: DetailedCompany) => company.EnvironmentStaffScoreValues).reduce((a: number, b: number) => a + b, 0),
            EnvironmentStaffPossibleValues: companies_with_same_size.map((company: DetailedCompany) => company.EnvironmentStaffPossibleValues).reduce((a: number, b: number) => a + b, 0),
            WorkplaceSupplierScoreValues: companies_with_same_size.map((company: DetailedCompany) => company.WorkplaceSupplierScoreValues).reduce((a: number, b: number) => a + b, 0),
            WorkplaceSupplierPossibleValues: companies_with_same_size.map((company: DetailedCompany) => company.WorkplaceSupplierPossibleValues).reduce((a: number, b: number) => a + b, 0),
            WorkplaceStaffScoreValues: companies_with_same_size.map((company: DetailedCompany) => company.WorkplaceStaffScoreValues).reduce((a: number, b: number) => a + b, 0),
            WorkplaceStaffPossibleValues: companies_with_same_size.map((company: DetailedCompany) => company.WorkplaceStaffPossibleValues).reduce((a: number, b: number) => a + b, 0),
            HIVSupplierScoreValues: companies_with_same_size.map((company: DetailedCompany) => company.HIVSupplierScoreValues).reduce((a: number, b: number) => a + b, 0),
            HIVSupplierPossibleValues: companies_with_same_size.map((company: DetailedCompany) => company.HIVSupplierPossibleValues).reduce((a: number, b: number) => a + b, 0),
            HIVStaffScoreValues: companies_with_same_size.map((company: DetailedCompany) => company.HIVStaffScoreValues).reduce((a: number, b: number) => a + b, 0),
            HIVStaffPossibleValues: companies_with_same_size.map((company: DetailedCompany) => company.HIVStaffPossibleValues).reduce((a: number, b: number) => a + b, 0),








            CompanyInfo: "All",
            CompanyFixes: "All",
            CompanyPositives: "All",
            CloutSASalesPercentage: "All",
            Ownership: "All",
            Employees: "All"

        }

        setAverageCompany(this_averageCompany);

        }

    }, [companies, company]);


    const handleChange = (event: SelectChangeEvent) => {
        // get the company with the selected company name
        let selected_company = companies.find((company) => company.Name === event.target.value);
        setCompany(selected_company);

      };

        return (
            <div>
                { company && averageCompany && (
                <Grid container 
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={2}
                >

                <Grid item xs={12}>
                    <Typography variant="h2" component="h2" color={'#000000'} marginBottom={'10px'}><b>Comparisons</b></Typography>
                    {loading && <p>Loading...</p>}
                    {error && <p>{error}</p>}
                </Grid>


                <Grid item xs={12} md={6}>
                <Typography variant="h5" component="h5" color={'#000000'} marginTop={'10px'}><b>Choose</b> Year</Typography>
            </Grid>

            <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Year</InputLabel>
                <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value="1"
                label="Year"
                onChange={handleChange}
                sx={{bgcolor:"#ffffff", height: "55px" }}>
                        <MenuItem value="1">2023</MenuItem>
                </Select>
                </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
            <Typography variant="h5" component="h5" color={'#000000'} marginTop={'10px'}><b>Choose</b> Company</Typography>
            
            </Grid>

            <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Company</InputLabel>
              <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={company.Name}
              label="Company"
              onChange={handleChange}
              sx={{bgcolor:"#ffffff", height: "55px" }}
              
              >
                {/* map the company in companies create a menu item with the company name, sorted alphabetically by company.Nmae */}
                {companies.sort((a, b) => a.Name.localeCompare(b.Name)).map((company) => (
                    <MenuItem value={company.Name}>{company.Name}</MenuItem>
                ))}
                


              </Select>
            </FormControl>
            </Grid>

            <Grid item xs={12}>
                    <hr />
                </Grid>


                <Grid item  xs={12} md={6}>
                    <Typography variant="h4" component="h4" color={'#000000'} marginBottom={'10px'}><b>{company.Name}</b></Typography>
                    Core Compliance vs Values Alignment
                    <DetailedCompanyGraph company={company} />
                </Grid> 

    
                <Grid item xs={12} md={6}>
                    <Typography variant="h4" component="h4" color={'#000000'} marginBottom={'10px'}><b>{company.Size}</b> Average</Typography>
                    Core Compliance vs Values Alignment Average
                    <DetailedCompanyGraph company={averageCompany} />
                </Grid>



                <Grid item  xs={12} md={6}>
                <Typography variant="h4" component="h4" color={'#000000'} marginBottom={'10px'}><b>{company.Name}</b></Typography>
                Supplier vs Staff Analysis
                    <SupplierStaffCompanyGraph company={company} />
                </Grid> 

    
                <Grid item xs={12} md={6}>
                <Typography variant="h4" component="h4" color={'#000000'} marginBottom={'10px'}><b>{company.Size}</b> Average</Typography>
                Supplier vs Staff Average
                    <SupplierStaffCompanyGraph company={averageCompany} />
                </Grid>




                </Grid>





                )}
            </div>
        );
    }

export default Comparison;