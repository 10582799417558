import React, {useState, useEffect} from "react";
import { Link } from "react-router-dom";
import { Pie, Doughnut, Line, Bar } from 'react-chartjs-2';
import ChartDataLabels from "chartjs-plugin-datalabels";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Paper, Grid, InputLabel, MenuItem, Button, FormControl, Typography, Dialog, DialogContent, DialogActions } from "@mui/material";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  LineElement,
  PointElement,
} from 'chart.js';

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend
);


function Country(): JSX.Element {

  // Design updates on this branch

  // Demographics
  const var_Demographics_Label = ['Less than 20', '20 - 25', '26 - 30', '31 - 35', '36 - 40', '41 - 45', '45 +']
  const var_Demographics_Australia = [56, 26, 8, 6, 1, 2, 1]
  const var_Demographics_Malaysia = [44, 31, 11, 3, 6, 3, 3]
  const var_Demographics_South_Africa = [27, 36, 18, 13, 4, 1, 1]
  const var_Demographics_United_Kingdom = [5, 15, 16, 24, 20, 12, 7]
  const var_Demographics_IMEA = [17, 17, 17, 25, 25, 0, 0]

  // Engagement
  const var_Engagement_Label = ['Yes', 'No']
  const var_Engagement_Australia = [70, 30]
  const var_Engagement_Malaysia = [44, 56]
  const var_Engagement_South_Africa = [60, 40]
  const var_Engagement_United_Kingdom = [62, 38]
  const var_Engagement_IMEA = [67, 33]

  // Activities Reason
  const var_Activities_Reasons_Label = ['Asked by local charity', 'Believe in community activities', 'Encouraged by line manager', 'Encouraged by wider business', 'Inspired by team', 'Passionate about local community', 'Other ']
  const var_Activities_Reasons_Australia = [25, 0, 14, 15, 15, 26, 2]
  const var_Activities_Reasons_Malaysia = [15, 22, 10, 19, 19, 18, 3]
  const var_Activities_Reasons_South_Africa = [19, 0, 13, 12, 12, 35, 8]
  const var_Activities_Reasons_United_Kingdom = [12, 0, 11, 14, 14, 29, 7]
  const var_Activities_Reasons_IMEA = [22, 0, 0, 11, 11, 44, 0]



  // // Acitivities Types
  // const var_Activities_Types_Label = ['Activity undertook', 'CV/Employment Skills', 'Clothes/Toiletries Collections', 'Fundraising', 'Job Offers', 'One-off Financial Donation', 'Product/Gift Voucher Donations', 'Volunteering', 'Work Placements', 'Other  ']
  // const var_Activities_Types_Australia = [44, 0, 7, 13, 2, 5, 19, 5, 2, 3]
  // const var_Activities_Types_Malaysia = [25, 6, 5, 6, 22, 6, 0, 0, 9, 20]
  // const var_Activities_Types_South_Africa = [42, 5, 7, 11, 12, 7, 0, 0, 9, 7]
  // const var_Activities_Types_United_Kingdom = [40, 5, 11, 21, 1, 7, 0, 0, 7, 9]
  // const var_Activities_Types_IMEA = [38, 13, 13, 0, 0, 13, 0, 0, 13, 13]



  // Acitivities Types
  const var_Activities_Types_Label = ['CV/Employment Skills', 'Clothes/Toiletries Collect', 'Fundraising', 'Job Offers', 'One-off Fin. Donation', 'Product/Gift Donations', 'Volunteering', 'Work Placements', 'Other  ']
  const var_Activities_Types_Australia = [ 0, 7, 13, 2, 5, 19, 5, 2, 3]
  const var_Activities_Types_Malaysia = [6, 5, 6, 22, 6, 0, 0, 9, 20]
  const var_Activities_Types_South_Africa = [5, 7, 11, 12, 7, 0, 0, 9, 7]
  const var_Activities_Types_United_Kingdom = [5, 11, 21, 1, 7, 0, 0, 7, 9]
  const var_Activities_Types_IMEA = [13, 13, 0, 0, 13, 0, 0, 13, 13]


  // Issues
  const var_Issues_Label = [' Education', 'Affordable  Housing', 'Caring for Animals', 'Climate Change', 'Drugs/Alcohol Abuse', 'Food Bank', 'Healthcare', 'Homelessness', 'Immigration', 'Income Inequality', 'LGBTQIA+', 'Mental Health', 'Pollution', 'Poverty', 'Refugees', 'Unemployment', 'Youth Development ', 'Other    ']
  const var_Issues_Australia = [8, 0, 0, 0, 1, 20, 5, 22, 1, 1, 0, 1, 3, 14, 0, 5, 18, 2]
  const var_Issues_Malaysia = [11, 6, 0, 0, 0, 9, 17, 5, 0, 0, 0, 3, 3, 13, 3, 11, 17, 2]
  const var_Issues_South_Africa = [17, 1, 1, 0, 4, 2, 7, 12, 1, 1, 1, 1, 0, 19, 0, 10, 21, 1]
  const var_Issues_United_Kingdom = [8, 0, 0, 0, 0, 18, 3, 27, 0, 2, 0, 4, 0, 13, 0, 8, 15, 1]
  const var_Issues_IMEA = [13, 13, 0, 0, 0, 13, 13, 13, 0, 0, 0, 0, 0, 13, 0, 13, 13, 0]

  // Contributions
  const var_Contributions__Label = ['$0 - $3000', '$3000 - $5000', '$5000 - $7000', '$7000 - $9000', '$9000 - $11000', '$11000 +']
  const var_Contributions__Australia = [57, 29, 5, 5, 5, 0]
  const var_Contributions__Malaysia = [81, 13, 0, 0, 3, 0]
  const var_Contributions__South_Africa = [36, 26, 20, 6, 1, 0]
  const var_Contributions__United_Kingdom = [72, 20, 4, 2, 2, 0]
  const var_Contributions__IMEA = [0, 25, 0, 25, 25, 0]

  //Impact
  const var_Impact__Label = ['More customer engage', 'Connected: Nandos brand', 'Connected: communnity', 'Heart and Soul score', 'Reduce absenteeism',  'Nandos brand values', 'Other     ']
  const var_Impact__Australia = [17, 23, 24, 0, 4, 31, 2]
  const var_Impact__Malaysia = [28, 13, 21, 13, 0, 25, 0]
  const var_Impact__South_Africa = [17, 17, 25, 13, 4, 22, 3]
  const var_Impact__United_Kingdom = [11, 17, 33, 8, 3, 24, 5]
  const var_Impact__IMEA = [17, 8, 33, 8, 0, 33, 0]

  //Goals
  const var_Goals__Label = ['Improve Brand/Marketing', 'Improve Team Morale', 'Reduce Chicken Waste', 'Tick a box', 'Want to make a difference', 'Other']
  const var_Goals__Australia = [19, 24, 13, 1, 41, 2]
  const var_Goals__Malaysia = [38, 29, 0, 5, 22, 5]
  const var_Goals__South_Africa = [32, 22, 2, 0, 42, 2]
  const var_Goals__United_Kingdom = [8, 10, 6, 0, 70, 6]
  const var_Goals__IMEA = [33, 22, 0, 0, 44, 0]

  // //Activities Proud
  // const var_Activities_Proud_Label = ['CV/Employment Skills ', 'Clothes/Toiletries Collections ', 'Fundraising ', 'Job Offers ', 'One-off Financial Donation ', 'Product/Gift Voucher Donations ', 'Proud of Doing', 'Volunteering ', 'Work Placements ', 'Other       ']
  // const var_Activities_Proud_Australia = [0, 6, 13, 4, 4, 16, 40, 11, 5, 2]
  // const var_Activities_Proud_Malaysia = [0, 4, 2, 20, 0, 0, 33, 18, 9, 13]
  // const var_Activities_Proud_South_Africa = [7, 6, 9, 7, 3, 0, 51, 11, 4, 2]
  // const var_Activities_Proud_United_Kingdom = [6, 11, 16, 5, 2, 0, 25, 17, 8, 10]
  // const var_Activities_Proud_IMEA = [0, 0, 0, 17, 17, 0, 17, 0, 17, 33]

  //Activities Proud
  const var_Activities_Proud_Label = ['CV/Employment Skills ', 'Clothes/Toiletries Collect ', 'Fundraising ', 'Job Offers ', 'One-off Fin. Donation ', 'Product/Gift Donations ',  'Volunteering ', 'Work Placements ', 'Other']
  const var_Activities_Proud_Australia = [0, 6, 13, 4, 4, 16,  11, 5, 2]
  const var_Activities_Proud_Malaysia = [0, 4, 2, 20, 0, 0,  18, 9, 13]
  const var_Activities_Proud_South_Africa = [7, 6, 9, 7, 3, 0,  11, 4, 2]
  const var_Activities_Proud_United_Kingdom = [6, 11, 16, 5, 2, 0,  17, 8, 10]
  const var_Activities_Proud_IMEA = [0, 0, 0, 17, 17, 0,  0, 17, 33]

  //Challenges
  const var_Challenges__Label = ['Budget', 'Cant find org.', 'Employees dont want to', 'Manager did not approve', 'No requests', 'No social challenges', 'Not enough time', 'Other                  ']
  const var_Challenges__Australia = [27, 13, 0, 0, 0, 13, 33, 13]
  const var_Challenges__Malaysia = [9, 30, 2, 0, 26, 15, 0, 17]
  const var_Challenges__South_Africa = [34, 11, 0, 9, 15, 12, 0, 19]
  const var_Challenges__United_Kingdom = [29, 12, 6, 0, 6, 6, 0, 41]
  const var_Challenges__IMEA = [0, 33, 0, 0, 0, 0, 0, 67]

  //Correlatins
  const var_Correlations__Label = ['0 - 15%', '16 - 30%', '31 - 45%', '46 - 60%', '61 - 75%', '76 - 90%', '91 - 105%', '106+']
  const var_Correlations__Australia = [13, 21, 25, 19, 10, 10, 2, 2]
  const var_Correlations__Malaysia = [25, 38, 0, 16, 13, 0, 6, 3]
  const var_Correlations__South_Africa = [32, 21, 14, 7, 7, 11, 7, 0]
  const var_Correlations__United_Kingdom = [11, 30, 20, 17, 13, 4, 4, 0]
  const var_Correlations__IMEA = [0, 100, 0, 0, 0, 0, 0, 0]




  ChartJS.register(ChartDataLabels);

  // Change default options for ALL charts
  ChartJS.defaults.set('plugins.datalabels', {
      color: '#ffffff',
      anchor: "end",
      align: "start"
  });

  const elv = 0;



  const [country, setCountry] = React.useState('Australia');
  const [imageNumber, setImageNumber] = React.useState(1);

  const [demographics, setDemographics] = React.useState(var_Demographics_Australia);
  const [engagement, setEngagement] = React.useState(var_Engagement_Australia);
  const [reason, setReason] = React.useState(var_Activities_Reasons_Australia);

  const [types, setTypes] = React.useState(var_Activities_Types_Australia);
  const [issues, setIssues] = React.useState(var_Issues_Australia);
  const [contributions, setContributions] = React.useState(var_Contributions__Australia);

  const [impact, setImpact] = React.useState(var_Impact__Australia);
  const [goals, setGoals] = React.useState(var_Goals__Australia);
  const [proud, setProud] = React.useState(var_Activities_Proud_Australia);

  const [challenges, setChallenges] = React.useState(var_Challenges__Australia);
  const [correlations, setCorrelations] = React.useState(var_Correlations__Australia);


  function updateCharts(cntr: string) {

    if (cntr == "Australia") {

      setDemographics(var_Demographics_Australia);
      setEngagement(var_Engagement_Australia);
      setReason(var_Activities_Reasons_Australia);

      setTypes(var_Activities_Types_Australia);
      setIssues(var_Issues_Australia);
      setContributions(var_Contributions__Australia);

      setImpact(var_Impact__Australia);
      setGoals(var_Goals__Australia);
      setProud(var_Activities_Proud_Australia);

      setChallenges(var_Challenges__Australia);
      setCorrelations(var_Correlations__Australia);

    }

    if (cntr == "Malaysia") {
      
      setDemographics(var_Demographics_Malaysia);
      setEngagement(var_Engagement_Malaysia);
      setReason(var_Activities_Reasons_Malaysia);

      setTypes(var_Activities_Types_Malaysia);
      setIssues(var_Issues_Malaysia);
      setContributions(var_Contributions__Malaysia);

      setImpact(var_Impact__Malaysia);
      setGoals(var_Goals__Malaysia);
      setProud(var_Activities_Proud_Malaysia);

      setChallenges(var_Challenges__Malaysia);
      setCorrelations(var_Correlations__Malaysia);

    }

    if (cntr == "South Africa") {
      
      setDemographics(var_Demographics_South_Africa);
      setEngagement(var_Engagement_South_Africa);
      setReason(var_Activities_Reasons_South_Africa);

      setTypes(var_Activities_Types_South_Africa);
      setIssues(var_Issues_South_Africa);
      setContributions(var_Contributions__South_Africa);

      setImpact(var_Impact__South_Africa);
      setGoals(var_Goals__South_Africa);
      setProud(var_Activities_Proud_South_Africa);

      setChallenges(var_Challenges__South_Africa);
      setCorrelations(var_Correlations__South_Africa);

    }

    if (cntr == "United Kingdom") {
      
      setDemographics(var_Demographics_United_Kingdom);
      setEngagement(var_Engagement_United_Kingdom);
      setReason(var_Activities_Reasons_United_Kingdom);

      setTypes(var_Activities_Types_United_Kingdom);
      setIssues(var_Issues_United_Kingdom);
      setContributions(var_Contributions__United_Kingdom);

      setImpact(var_Impact__United_Kingdom);
      setGoals(var_Goals__United_Kingdom);
      setProud(var_Activities_Proud_United_Kingdom);

      setChallenges(var_Challenges__United_Kingdom);
      setCorrelations(var_Correlations__United_Kingdom);

    }

    if (cntr == "IMEA") {
      
      setDemographics(var_Demographics_IMEA);
      setEngagement(var_Engagement_IMEA);
      setReason(var_Activities_Reasons_IMEA);

      setTypes(var_Activities_Types_IMEA);
      setIssues(var_Issues_IMEA);
      setContributions(var_Contributions__IMEA);

      setImpact(var_Impact__IMEA);
      setGoals(var_Goals__IMEA);
      setProud(var_Activities_Proud_IMEA);

      setChallenges(var_Challenges__IMEA);
      setCorrelations(var_Correlations__IMEA);

    }
  }

  const handleChange = (event: SelectChangeEvent) => {
    setCountry(event.target.value as string);
    updateCharts(event.target.value as string);

  };

  //------DEMOGRAPHIC GRAPH------//
  const demographicsoptions = {
    label: {display:false},
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: country + ': Number of Employees in Restaurants',
      },
      datalabels: {
          formatter: function(value:number) {
            return value +'%';
          }
      }
    },
  };

  const demographicsdata = {
    labels: var_Demographics_Label,
    datasets: [
      {
        label: '%',
        data: demographics,
        backgroundColor: [
          '#151531',
          '#421A92',
          '#404BE3',
          '#39BEFF',
          '#45EBAF',
          '#1B0083',
        ],
        borderColor: [
          '#151531',
          '#421A92',
          '#404BE3',
          '#39BEFF',
          '#45EBAF',
          '#1B0083',
        ],
        borderWidth: 1,
      },
    ],
  };

  //------ENGAGEMENT GRAPH------//
  const engagementoptions = {
    plugins: {
      legend: {
        position: 'right' as const,
      },
      title: {
        display: true,
        text: [country + ': Restaurants that have undertaken ','community activities within the last 12 months'],
      },
      datalabels: {
          formatter: function(value:number) {
            return value +'%';
          }
      }
    }
  };

  const engagementdata = {
    labels: var_Engagement_Label,

    datasets: [
      {
        label: '%',
        data: engagement,
        backgroundColor: [
          '#151531',
          '#421A92'
        ],
        borderColor: [
          '#151531',
          '#421A92'
        ],
        borderWidth: 1,
      },
    ],
  };

  //------REASON GRAPH------//
  const reasonoptions = {
    maintainAspectRatio: false,

    label: {display:false},
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: [country + ': Reasons why Patraos have', 'undertaken community activities'],
      },
      datalabels: {
          formatter: function(value:number) {
            return value +'%';
          },
          color: 'black'
      }
    },
  };

  const reasondata = {
    labels: var_Activities_Reasons_Label,
    datasets: [
      {
        label: '%',
        data: reason,
        backgroundColor: [
          '#151531',
          '#421A92',
          '#404BE3',
          '#39BEFF',
          '#45EBAF',
          '#1B0083',
        ],
        borderColor: [
          '#45EBAF',
          '#421A92',
          '#404BE3',
          '#39BEFF',
          '#45EBAF',
          '#1B0083',
        ],
        borderWidth: 2,
      },
    ],
  };


  //------TYPE GRAPH------//
  const typeoptions = {
    maintainAspectRatio: false,
    indexAxis: 'y' as const,
    label: {display:false},
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: country + ': The type of activities the restaurants undertook',
      },
      datalabels: {
          formatter: function(value:number) {
            return value +'%';
          }
      },
    },
  };

  const typedata = {
    labels: var_Activities_Types_Label,
    datasets: [
      {
        label: '%',
        data: types,
        backgroundColor: [
          '#151531',
          '#421A92',
          '#404BE3',
          '#39BEFF',
          '#45EBAF',
          '#1B0083',
        ],
        borderColor: [
          '#151531',
          '#421A92',
          '#404BE3',
          '#39BEFF',
          '#45EBAF',
          '#1B0083',
        ],
        borderWidth: 1,
      },
    ],
  };



  //------ISSUES GRAPH------//
  const issuesoptions = {
    maintainAspectRatio: false,
    indexAxis: 'y' as const,
    label: {display:false},
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: country + ': The social issues these activities address',
      },
      datalabels: {
          formatter: function(value:number) {
            return value +'%';
          },
          color: 'black'
      },
    },
  };

  const issuesdata = {
    labels: var_Issues_Label,
    datasets: [
      {
        label: '%',
        data: issues,
        backgroundColor: [
          '#151531',
          '#421A92',
          '#404BE3',
          '#39BEFF',
          '#45EBAF',
          '#1B0083',
        ],
        borderColor: [
          '#45EBAF',
          '#421A92',
          '#404BE3',
          '#39BEFF',
          '#45EBAF',
          '#1B0083',
        ],
        borderWidth: 2,
      },
    ],
  };

  //------CONTRIBUTIONS GRAPH------//
  const contributionsoptions = {
    label: {display:false},
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: [country + ': The total monetary value contributed ','towards community activities over the last 12 months'],
      },
      datalabels: {
          formatter: function(value:number) {
            return value +'%';
          }
      },
    },
  };

  const contributionsdata = {
    labels: var_Contributions__Label,
    datasets: [
      {
        label: '%',
        data: contributions,
        backgroundColor: [
          '#151531',
          '#421A92',
          '#404BE3',
          '#39BEFF',
          '#45EBAF',
          '#1B0083',
        ],
        borderColor: [
          '#151531',
          '#421A92',
          '#404BE3',
          '#39BEFF',
          '#45EBAF',
          '#1B0083',
        ],
        borderWidth: 1,
      },
    ],
  };

  //------IMPACT GRAPH------//
  const impactoptions = {
    indexAxis: 'y' as const,
    label: {display:false},    
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: [country + ': Impact Patraos believe the restaurants', 'community activities have on employees'],

        
      },
      datalabels: {
          formatter: function(value:number) {
            return value +'%';
          }
      },
    },
  };

  const impactdata = {
    labels: var_Impact__Label,
    datasets: [
      {
        label: '%',
        data: impact,
        backgroundColor: [
          '#151531',
          '#421A92',
          '#404BE3',
          '#39BEFF',
          '#45EBAF',
          '#1B0083',
        ],
        borderColor: [
          '#151531',
          '#421A92',
          '#404BE3',
          '#39BEFF',
          '#45EBAF',
          '#1B0083',
        ],
        borderWidth: 1,
      },
    ],
  };



  //------GOALS GRAPH------//
  const goalsoptions = {
    label: {display:false},
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: [country + ': The intended goal of the activities'],
      },
      datalabels: {
          formatter: function(value:number) {
            return value +'%';
          },
          color: 'black'
      },
    },
  };

  const goalsdata = {
    labels: var_Goals__Label,
    datasets: [
      {
        label: '%',
        data: goals,
        backgroundColor: [
          '#151531',
          '#421A92',
          '#404BE3',
          '#39BEFF',
          '#45EBAF',
          '#1B0083',
        ],
        borderColor: [
          '#45EBAF',
          '#421A92',
          '#404BE3',
          '#39BEFF',
          '#45EBAF',
          '#1B0083',
        ],
        borderWidth: 2,
      },
    ],
  };


  //------PROUD GRAPH------//
  const proudoptions = {
    maintainAspectRatio: false,
    indexAxis: 'y' as const,
    label: {display:false},
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: [country + ': The community activity', 'Patraos were most proud of doing'],
      },
      datalabels: {
          formatter: function(value:number) {
            return value +'%';
          },
          color: 'black'
      },
    },
  };

  const prouddata = {
    labels: var_Activities_Proud_Label,
    datasets: [
      {
        label: '%',
        data: proud,
        backgroundColor: [
          '#151531',
          '#421A92',
          '#404BE3',
          '#39BEFF',
          '#45EBAF',
          '#1B0083',
        ],
        borderColor: [
          '#45EBAF',
          '#421A92',
          '#404BE3',
          '#39BEFF',
          '#45EBAF',
          '#1B0083',
        ],
        borderWidth: 2,
      },
    ],
  };


  //------CHALLENGES GRAPH------//
  const challengesoptions = {
    maintainAspectRatio: false,
    indexAxis: 'y' as const,
    label: {display:false},
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: country + ': Challenges Patraos face implementing activities',
      },
      datalabels: {
          formatter: function(value:number) {
            return value +'%';
          }
      },
    },
  };

  const challengesdata = {
    labels: var_Challenges__Label,
    datasets: [
      {
        label: '%',
        data: challenges,
        backgroundColor: [
          '#151531',
          '#421A92',
          '#404BE3',
          '#39BEFF',
          '#45EBAF',
          '#1B0083',
        ],
        borderColor: [
          '#151531',
          '#421A92',
          '#404BE3',
          '#39BEFF',
          '#45EBAF',
          '#1B0083',
        ],
        borderWidth: 1,
      },
    ],
  };



  //------CORRELATIONS GRAPH------//
  const correlationsoptions = {
    label: {display:false},
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: [country + ': Correlation between the' ,'percentage of restaurants that have undertaken' ,'activities and employee turnover rates'],
      },
      datalabels: {
          formatter: function(value:number) {
            return value +'%';
          }
      },
    },
  };

  const correlationsdata = {
    labels: var_Correlations__Label,
    datasets: [
      {
        label: '%',
        data: correlations,
        backgroundColor: [
          '#151531',
          '#421A92',
          '#404BE3',
          '#39BEFF',
          '#45EBAF',
          '#1B0083',
        ],
        borderColor: [
          '#151531',
          '#421A92',
          '#404BE3',
          '#39BEFF',
          '#45EBAF',
          '#1B0083',
        ],
        borderWidth: 1,
      },
    ],
  };
  
  //------POSITIVE GRAPH------//
  const positiveoptions = {    
    elements: {
    bar: {
      borderWidth: 1,
    },
  },
    plugins: {
      legend: {
        position: 'right' as const,
      },
      title: {
        display: true,
        text: [country + ': Patraos believe activities had positive impact', ,' on employees activities had positive impact on employees activities had positive impact on employees']
      },
      datalabels: {
          formatter: function(value:number) {
            return value +'%';
          }
      },
    }
  };
  
  const positivedata = {
    labels: [1,2,3,4,5],
    datasets: [
      {
        borderWidth: 1,
        label: country,
        data: [3,13,21,48,17],
        borderColor: 'rgba(206,9,37, 1)',
        backgroundColor: 'rgba(206,9,37, 1)',
      },
      {
        borderWidth: 1,
        label: 'Other',
        data: [2,10,26,27,36],
        borderColor: 'rgba(0,120,140, 1)',
        backgroundColor: 'rgba(0,120,140, 1)',
      },
    ],
  };
  
  //------OPEN GRAPH------//
  const openoptions = {
    plugins: {
      legend: {
        position: 'right' as const,
      },
      title: {
        display: true,
        text: country + ': When were restaurant opened?',
      },
      datalabels: {
          formatter: function(value:number) {
            return value +'%';
          }
      },
    }
  };

  const opendata = {
    labels: ["Before 2015","After 2015"],

    datasets: [
      {
        label: '%',
        data: [12,21],
        backgroundColor: [
          'rgba(75, 192, 192, 1)',
          'rgba(255, 99, 132, 1)',
        ],
        borderColor: [
          'rgba(75, 192, 192, 1)',
          'rgba(255, 99, 132, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  //------MODAL SETUP WITH IMAGES-------
  const [currentImages, setImages] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [selectedImage, setSelectedImage] = React.useState("");
  const [graphType, setGraphType] = React.useState("");


  const handleClickOpen = (image_type:string) => {
    setGraphType(image_type);
    let image_name = "/" + country + "_" + image_type + "_" + imageNumber + ".png"
    setSelectedImage(image_name);
    setOpen(true);

    let new_number = imageNumber+1;

    if (imageNumber === graph_types_max[image_type]) {
      new_number = 1;
    }

    setImageNumber(new_number);
  };

  const handleClose = () => {
    setImageNumber(1);
    setOpen(false);
  };

  const buttonSX = {
    marginTop: "8px",
    bgcolor: "#333333",
    "&:hover": {
      bgcolor: "#434343",
    },
  };
  const buttonSX2 = {
    marginTop: "8px",
    color:"#333333", 
    borderColor:"#333333",
    "&:hover": {
      color:"#434343", 
      borderColor:"#434343",
    },
  };

  let graph_types_max: any = {};

  graph_types_max["Demographics"] = 3;
  graph_types_max["Engagement"] = 2;
  graph_types_max["Reasons"] = 3;
  graph_types_max["Types"] = 2;
  graph_types_max["Issues"] = 2;
  graph_types_max["Contributions"] = 1;
  graph_types_max["Impact"] = 4;
  graph_types_max["Goals"] = 1;
  graph_types_max["Proud"] = 4;
  graph_types_max["Challenges"] = 2;
  graph_types_max["Comparisons"] = 8;

  
  function getImageNumber(image_name:string){


    if (image_name == "") {
      return 0;
    }

    let last_text = image_name.split("_")[2]
    let img_nr = last_text.split(".")[0]
    return(img_nr);
  }


  return (
    <div>

      


<Grid container 
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          sx={{marginBottom:"15px"}}>

                <Grid item xs={12}>
                    <Typography variant="h4" component="h4" color={'#000000'} marginBottom={'20px'}><b>Country</b> Activities</Typography>
                </Grid>

            
            <Grid item xs={12} md={6}>
            <Typography variant="h5" component="h5" color={'#000000'} marginTop={'10px'}><b>Choose</b> Country</Typography>
            </Grid>

            <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Country</InputLabel>
              <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={country}
              label="Country"
              onChange={handleChange}
              sx={{bgcolor:"#ffffff", height: "55px" }}
              
              >
                <MenuItem value={'Australia'}>Australia</MenuItem>
                <MenuItem value={'IMEA'}>IMEA</MenuItem>
                <MenuItem value={'Malaysia'}>Malaysia</MenuItem>
                <MenuItem value={'South Africa'}>South Africa</MenuItem>
                <MenuItem value={'United Kingdom'}>United Kingdom</MenuItem>
              </Select>
            </FormControl>
            </Grid>
          </Grid> 




          <Grid container 
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          sx={{marginBottom:"15px"}}>

            
            <Grid item xs={12} md={6}>
            <Typography variant="h5" component="h5" color={'#000000'} marginTop={'10px'}><b>Choose</b> Year</Typography>
            </Grid>

            <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label-year">Year</InputLabel>
              <Select
              labelId="demo-simple-select-label-yar"
              id="demo-simple-select-year"
              value={'2020'}
              label="Country"
              sx={{bgcolor:"#ffffff", height: "55px" }}
              
              >
                <MenuItem value={'2020'}>2020</MenuItem>
              </Select>
            </FormControl>
            </Grid>
          </Grid> 


        <Grid container 
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={2}>

        <Grid item xs={12} md={6} lg={4}>
          <Paper elevation={elv} sx={{
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          borderRadius: 2,
          }}>
            <h4>DEMOGRAPHICS</h4>
            <div style={{height:"50vh",position:"relative"}}>
              <Bar options={demographicsoptions} data={demographicsdata} />
            </div>
            
            <Button variant="outlined" sx={buttonSX2} onClick={() => handleClickOpen("Demographics")}> See All- Demographics </Button>
          </Paper>
        </Grid>


        <Grid item xs={12} md={6} lg={4}>
          <Paper elevation={elv} sx={{
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          borderRadius: 2,
          }}>
            <h4>ENGAGEMENT</h4>
            <div style={{height:"50vh",position:"relative"}}>
            <Pie options={engagementoptions} data={engagementdata} />
            </div>
            
            <Button variant="outlined" sx={buttonSX2} onClick={() => handleClickOpen("Engagement")}> See All- Engagement </Button>
          </Paper>
        </Grid>


        <Grid item xs={12} md={6} lg={4}>
          <Paper elevation={elv} sx={{
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          borderRadius: 2,
          }}>
            <h4>REASONS</h4>
            <div style={{height:"50vh",position:"relative"}}>
            <Line options={reasonoptions} data={reasondata} />
            </div>

           
            <Button variant="outlined" sx={buttonSX2} onClick={() => handleClickOpen("Reasons")}> See All- Reasons </Button>
          </Paper>
        </Grid>






        <Grid item xs={12} md={6} lg={4}>
          <Paper elevation={elv} sx={{
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          borderRadius: 2,
          }}>
            <h4>TYPES</h4>
            <div style={{height:"70vh",position:"relative"}}>
              <Bar options={typeoptions} data={typedata} />
            </div>
            <Button variant="outlined" sx={buttonSX2} onClick={() => handleClickOpen("Types")}> See All- Types </Button>
          </Paper>
        </Grid>


        <Grid item xs={12} md={6} lg={4}>
          <Paper elevation={elv} sx={{
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          borderRadius: 2,
          }}>
            <h4>SOCIAL ISSUES</h4>
            <div style={{height:"70vh",position:"relative"}}>
              <Line options={issuesoptions} data={issuesdata} />
            </div>
            <Button variant="outlined" sx={buttonSX2} onClick={() => handleClickOpen("Issues")}> See All- Issues </Button>
          </Paper>
        </Grid>


        <Grid item xs={12} md={6} lg={4}>
          <Paper elevation={elv} sx={{
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          borderRadius: 2,
          }}>
            <h4>FINANCIAL CONTRIBUTIONS</h4>
            <div style={{height:"72vh",position:"relative"}}>
              <Bar options={contributionsoptions} data={contributionsdata} />
            </div>
            {/* 
            <Button variant="outlined" sx={buttonSX2} onClick={() => handleClickOpen("Contributions")}> See All- Contributions </Button>
        */}
          </Paper>
        </Grid>




        <Grid item xs={12} md={6} lg={4}>
          <Paper elevation={elv} sx={{
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          borderRadius: 2,
          }}>
            <h4>IMPACT</h4>
            <div style={{height:"50vh",position:"relative"}}>
              <Bar options={impactoptions} data={impactdata} />
            </div>
            <Button variant="outlined" sx={buttonSX2} onClick={() => handleClickOpen("Impact")}> See All- Impact </Button>
          </Paper>
        </Grid>


        <Grid item xs={12} md={6} lg={4}>
          <Paper elevation={elv} sx={{
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          borderRadius: 2,
          }}>
            <h4>GOALS</h4>
            <div style={{height:"57vh",position:"relative"}}>
              <Line options={goalsoptions} data={goalsdata} />
            </div>
            {/* 
            <Button variant="outlined" sx={buttonSX2} onClick={() => handleClickOpen("Goals")}> See All- Goals </Button>
        */}
            </Paper>
        </Grid>


        <Grid item xs={12} md={6} lg={4}>
          <Paper elevation={elv} sx={{
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          borderRadius: 2,
          }}>
            <h4>PROUD</h4>
            <div style={{height:"50vh",position:"relative"}}>
              <Line options={proudoptions} data={prouddata} />
            </div>
            
            <Button variant="outlined" sx={buttonSX2} onClick={() => handleClickOpen("Proud")}> See All- Proud </Button>

            
          </Paper>
        </Grid>




        <Grid item xs={12} md={6} lg={4}>
          <Paper elevation={elv} sx={{
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          borderRadius: 2,
          }}>
            <h4>CHALLENGES</h4>
            <div style={{height:"50vh",position:"relative"}}>
              <Bar options={challengesoptions} data={challengesdata} />
            </div>

            <Button variant="outlined" sx={buttonSX2} onClick={() => handleClickOpen("Challenges")}> See All- Challenges </Button>
          </Paper>
        </Grid>


        <Grid item xs={12} md={6} lg={4}>
          <Paper elevation={elv} sx={{
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          borderRadius: 2,
          }}>
            <h4>COMPARISONS</h4>
            <div style={{height:"50vh",position:"relative"}}>
              <Bar options={correlationsoptions} data={correlationsdata} />
            </div>
            <Button variant="outlined" sx={buttonSX2} onClick={() => handleClickOpen("Comparisons")}> See All- Comparisons </Button>
          </Paper>
        </Grid>


        <Dialog
        fullWidth
        maxWidth="md"
        open={open}
        onClose={handleClose}
      >

        <DialogContent>


          <Grid container 
          direction="row"
          justifyContent="center"
          alignItems="center"
          >
          <Grid item lg={10}>
            <img width={700} src={selectedImage} />

            </Grid>

            <Grid item lg={2}>
          <Button onClick={() => handleClickOpen(graphType)}>
          ({getImageNumber(selectedImage)} of {graph_types_max[graphType]}) <br /> Next 
          </Button> 

            </Grid>
          
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>
            Exit
          </Button>
        </DialogActions>
      </Dialog>


      </Grid>


    </div>
  );
}

export default Country;
