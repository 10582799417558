import React from "react";
import { Link, useParams, useLocation } from "react-router-dom";

function Detail(): JSX.Element {
  const {img} = useParams();
  const backlink = img && img.split('_')[0]

  console.log(img);
  console.log(backlink);
  
  return (
    <div>
        <div>
        </div>
        <div>
          <Link className="link-text" to={{
              pathname: "/"+backlink
            }}>
             &#60; Back
            </Link> <br/>
            {/*<h1 style={{ textAlign: "center", marginTop: "10px" }}>Detail</h1>*/}
            <img width={1000} src={"/"+img} />
            
        </div>
    </div>
  );
}

export default Detail;
