import { AuthenticationDetails, CognitoUser } from "amazon-cognito-identity-js";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import UserPool from "../Configs/UserPool";
import {Grid, Link, TextField, Button, CssBaseline, Typography, Paper, Box, Avatar, Hidden, OutlinedInput, InputAdornment} from '@mui/material';

import { styled } from "@mui/material";

const StyledButton= styled(Button)(({theme}) => ({
  border: 2,
  borderRadius: 3,
  height: 48,
  padding: '0 30px',
  margin: theme.spacing(3, 0, 1),
  backgroundColor: "#333333",
  "&:hover": {
    backgroundColor:"#434343", 
    borderColor:"#434343",
  },
}));

const StyledTextField= styled(TextField)(({theme}) => ({

}));






// import {Grid, Link, TextField, Button, CssBaseline, Typography, Paper, Avatar, Hidden, OutlinedInput, InputAdornment} from '@material-ui/core';
// import { makeStyles, Theme, createStyles } from "@material-ui/core/styles"; 

// const useStyles = makeStyles((theme?: any) => ({
//   avatar: {
//     margin: theme.spacing(1),
//     backgroundColor: theme.palette.primary,
//     marginBottom: theme.spacing(2),
// },
// submit: {
//     border: 2,
//     borderRadius: 3,
//     height: 48,
//     padding: '0 30px',
//     margin: theme.spacing(3, 0, 1),
//     backgroundColor: "#333333",
//     "&:hover": {
//       backgroundColor:"#434343", 
//       borderColor:"#434343",
//     },
// },
// sso: {
//     border: 2,
//     borderRadius: 3,
//     height: 48,
//     padding: '0 30px',
//     margin: theme.spacing(1, 0, 1)
// },
// paper: {
//     display: 'flex',
//     flexDirection: 'column',
//     alignItems: 'center',
//     justifyContent: 'center'
// },
// form: {
//     width: '90%',
//     marginTop: theme.spacing(4),
//     [theme.breakpoints.up(800 + theme.spacing(2) * 2)]: {
//       width: '60%',
//     marginTop: theme.spacing(4),
//     },
// },
// root: {
//     height: '100vh',
// },
// image: {
//     backgroundImage: 'url(./viewer_portal_logo_login.png)',
//     backgroundRepeat: 'no-repeat',
//     // backgroundColor: "#f4f4f4",
//     // backgroundSize: 'cover',
//     backgroundPosition: 'center'
// },
// space: {
//   marginTop: "10px"
// },
// label: {
//   '&$focused': {
//     color: '#333333'
//   },
// },
// focused: {},
// outlinedInput: {
//   '&$focused $notchedOutline': {
//     border: '2px solid #333333'
//   },
// },
// notchedOutline: {},
// }));

 
const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  // const [, setUser] = useContext(UserContext);
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  //const classes = useStyles();

  

  const userLogin = () => {
    setLoading(true);
    const user = new CognitoUser({
      Username: email,
      Pool: UserPool,
    });

    const authDetails = new AuthenticationDetails({
      Username: email,
      Password: password,
    });

    user.authenticateUser(authDetails, {
      onSuccess: (data: any) => {
        localStorage.setItem("accessToken", data.accessToken.jwtToken);
        // setUser({ access: data.accessToken.jwtToken });
        setLoading(false);
        navigate("/project");
      },
      onFailure: (error) => {
        console.log("onFailure: ", error);
        localStorage.setItem("accessToken", "none");
        // setUser({ access: "none" });
        alert('Incorrect username or password.');
        navigate("/");
        return;
      },
    });
  };

  return (
    <>
      {loading && (
        <div style={{ width: "100%", textAlign: "center", position: "absolute", backgroundColor: "grey", height: "100vh", zIndex: "9999", opacity: "0.4", paddingTop: "40vh" }}>
          <div className="spinner-border float-right" role="status">
            <span className="sr-only"></span>
          </div>
        </div>
      )}
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      height="100vh"
      width="100vw"
    >
      <Box
        border="1px solid black"
        borderRadius="10px"
        padding="20px"
        textAlign="center"
      >



        <img src={process.env.PUBLIC_URL + '/viewer_portal_logo.png'} alt="logo" height="150px" />

              <Typography component="h6" variant="h6">
                <b> Welcome! </b> Sign in to Viewer Portal below
              </Typography>
              <hr />

              <form >
                <>
                <Typography variant="subtitle1"> Enter Email </Typography>

                <StyledTextField
                  onChange={(e: any) => setEmail(e.target.value)}
                  type="email"
                  required
                  fullWidth
                  variant="outlined"
                  InputLabelProps={{shrink: false}}
                  InputProps={{
  
                  }}
                />

                <Typography variant="subtitle1"> Enter Password </Typography>

                <StyledTextField
                  onChange={(e: any) => setPassword(e.target.value)}
                  type="password"
                  required
                  fullWidth
                  variant="outlined"
                  InputLabelProps={{shrink: false}}
                  InputProps={{

                  }}
                />
                     
                <StyledButton
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={() => userLogin()}
                >
                  Login
                </StyledButton>
                </>
                                                   
              </form>


            </Box>                
          </Grid>

      {/* <section className="vh-100">
        <div className="container-fluid h-custom">
          <div className="row d-flex justify-content-center align-items-center h-100">

            <div className="col-md-9 col-lg-6 col-xl-3">
              <div className="d-flex align-items-center justify-content-center">
              </div> */}
              {/* <img src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/draw2.webp" className="img-fluid" alt="" /> */}
              {/* <div className="d-flex align-items-center justify-content-center">
                <img width={450} src={"/ViewerPortalLogin.png"} />
              </div>
            </div>

            <div className="col-md-8 col-lg-6 col-xl-3 offset-xl-1">
              <form>
                
                <p className="heading-text">Sign In</p>
                <div className="form-outline mb-4"> */}
                  {/* <label className="form-label">Email address</label>
                  <input type="email" id="form3Example3" onChange={(e: any) => setEmail(e.target.value)} className="form-control form-control-lg" placeholder="Enter a valid email address" /> */}
                  {/* <TextField
                                    onChange={(e: any) => setEmail(e.target.value)}
                                    type="email"
                                    margin="normal"
                                    id="form3Example3"
                                    required
                                    fullWidth
                                    label="Username"
                                    autoFocus
                                    variant="outlined"
                                    color="primary"
                                />
                </div>

                <div className="form-outline mb-3"> */}
                  {/* <label className="form-label">Password</label>
                  <input type="password" id="form3Example4" onChange={(e: any) => setPassword(e.target.value)} className="form-control form-control-lg" placeholder="Enter password" /> */}
                  {/* <TextField
                                    onChange={(e: any) => setPassword(e.target.value)}
                                    type="password"
                                    margin="normal"
                                    id="form3Example4"
                                    required
                                    fullWidth
                                    label="Password"
                                    autoFocus
                                    variant="outlined"
                                    color="primary"
                                />
                </div>

                <div className="text-center text-lg-start mt-4 pt-2"> */}
                  {/* <button onClick={() => userLogin()} type="button" className="btn btn-primary btn-lg" style={{ paddingLeft: "2.5rem", width: "100%", paddingRight: "2.5rem" }}>
                    Login
                  </button> */}
                  {/* <Button
                                    fullWidth
                                    variant="contained"
                                    className={classes.submit}
                                    color="primary"
                                    onClick={() => userLogin()}
                                >

                                    Login
                                </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section> */}
    </>
  );
};

export default Login;
