import React, {useState, useEffect} from "react";
import {useParams} from "react-router-dom";
import DetailedCompanyGraph from "../Components/DetailedCompanyGraph";
import SupplierStaffCompanyGraph from "../Components/SupplierStaffCompanyGraph";
import {DetailedCompany} from "../Models/DetailedCompany";
import { Category } from "../Models/Category";
import { Link } from "react-router-dom";
import CategoryGraph from "../Components/CategoryGraph";
import { Paper, Grid, InputLabel, MenuItem, Button, FormControl, Box, Typography, Dialog, DialogContent, DialogActions } from "@mui/material";
import Select, { SelectChangeEvent } from '@mui/material/Select';

function Dashboard(): JSX.Element {

    // read an environment variable
    const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
    const GOOGLE_SHEET = process.env.REACT_APP_GOOGLE_SHEET;
    const GOOGLE_SHEET_TAB = process.env.REACT_APP_GOOGLE_SHEET_TAB;
    const GOOGLE_SHEET_TAB2 = 'ETL';

    // get the companies data
    const [companies, setCompanies] = useState<DetailedCompany[]>([]);
    const [categories, setCategories] = useState<Category[]>([]);

    const [companyType, setCompanyType] = React.useState('6 or more');

    const [companiesAverage, setCompaniesAverage] = useState<DetailedCompany[]>([]);
    const [categoriesAverage, setCategoriesAverage] = useState<Category[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    function parseAndConvert(input: string) {
        const parsedNumber = parseInt(input, 10);
        return isNaN(parsedNumber) ? 0 : parsedNumber;
      }

    useEffect(() => {
        fetch(`https://sheets.googleapis.com/v4/spreadsheets/${GOOGLE_SHEET}/values/${GOOGLE_SHEET_TAB2}?key=${GOOGLE_API_KEY}`)
            .then((response) => response.json())
            .then((data) => {

                // parse the data into the company model
                let companies_array: DetailedCompany[] = [];
                let rows = data.values;
                for (let i = 1; i < rows.length; i++) {
                    let row = rows[i];

                    // if row[0] = companyType
                    if (row[0] === companyType) {

                        let company: DetailedCompany = {
                            
                            Size: row[0],
                            Name: row[1],
                            LegalSupplierScoreCore: parseAndConvert(row[2]),
                            LegalSupplierPossibleCore: parseAndConvert(row[3]),
                            LegalStaffScoreCore: parseAndConvert(row[4]),
                            LegalStaffPossibleCore: parseAndConvert(row[5]),
                            OwnershipSupplierScoreCore: parseAndConvert(row[6]),
                            OwnershipSupplierPossibleCore: parseAndConvert(row[7]),
                            OwnershipStaffScoreCore: parseAndConvert(row[8]),
                            OwnershipStaffPossibleCore: parseAndConvert(row[9]),
                            EquitySupplierScoreCore: parseAndConvert(row[10]),
                            EquitySupplierPossibleCore: parseAndConvert(row[11]),
                            EquityStaffScoreCore: parseAndConvert(row[12]),
                            EquityStaffPossibleCore: parseAndConvert(row[13]),
                            SkillsDevelopmentSupplierScoreCore: parseAndConvert(row[14]),
                            SkillsDevelopmentSupplierPossibleCore: parseAndConvert(row[15]),
                            SkillsDevelopmentStaffScoreCore: parseAndConvert(row[16]),
                            SkillsDevelopmentStaffPossibleCore: parseAndConvert(row[17]),
                            HRSupplierScoreCore: parseAndConvert(row[18]),
                            HRSupplierPossibleCore: parseAndConvert(row[19]),
                            HRStaffScoreCore: parseAndConvert(row[20]),
                            HRStaffPossibleCore: parseAndConvert(row[21]),
                            SocialInvestmentSupplierScoreCore: parseAndConvert(row[22]),
                            SocialInvestmentSupplierPossibleCore: parseAndConvert(row[23]),
                            SocialInvestmentStaffScoreCore: parseAndConvert(row[24]),
                            SocialInvestmentStaffPossibleCore: parseAndConvert(row[25]),
                            ProcurementSupplierScoreCore: parseAndConvert(row[26]),
                            ProcurementSupplierPossibleCore: parseAndConvert(row[27]),
                            ProcurementStaffScoreCore: parseAndConvert(row[28]),
                            ProcurementStaffPossibleCore: parseAndConvert(row[29]),
                            HealthSupplierScoreCore: parseAndConvert(row[30]),
                            HealthSupplierPossibleCore: parseAndConvert(row[31]),
                            HealthStaffScoreCore: parseAndConvert(row[32]),
                            HealthStaffPossibleCore: parseAndConvert(row[33]),
                            EnvironmentSupplierScoreCore: parseAndConvert(row[34]),
                            EnvironmentSupplierPossibleCore: parseAndConvert(row[35]),
                            EnvironmentStaffScoreCore: parseAndConvert(row[36]),
                            EnvironmentStaffPossibleCore: parseAndConvert(row[37]),
                            WorkplaceSupplierScoreCore: parseAndConvert(row[38]),
                            WorkplaceSupplierPossibleCore: parseAndConvert(row[39]),
                            WorkplaceStaffScoreCore: parseAndConvert(row[40]),
                            WorkplaceStaffPossibleCore: parseAndConvert(row[41]),
                            HIVSupplierScoreCore: parseAndConvert(row[42]),
                            HIVSupplierPossibleCore: parseAndConvert(row[43]),
                            HIVStaffScoreCore: parseAndConvert(row[44]),
                            HIVStaffPossibleCore: parseAndConvert(row[45]),
                            LegalSupplierScoreValues: parseAndConvert(row[46]),
                            LegalSupplierPossibleValues: parseAndConvert(row[47]),
                            LegalStaffScoreValues: parseAndConvert(row[48]),
                            LegalStaffPossibleValues: parseAndConvert(row[49]),
                            OwnershipSupplierScoreValues: parseAndConvert(row[50]),
                            OwnershipSupplierPossibleValues: parseAndConvert(row[51]),
                            OwnershipStaffScoreValues: parseAndConvert(row[52]),
                            OwnershipStaffPossibleValues: parseAndConvert(row[53]),
                            EquitySupplierScoreValues: parseAndConvert(row[54]),
                            EquitySupplierPossibleValues: parseAndConvert(row[55]),
                            EquityStaffScoreValues: parseAndConvert(row[56]),
                            EquityStaffPossibleValues: parseAndConvert(row[57]),
                            SkillsDevelopmentSupplierScoreValues: parseAndConvert(row[58]),
                            SkillsDevelopmentSupplierPossibleValues: parseAndConvert(row[59]),
                            SkillsDevelopmentStaffScoreValues: parseAndConvert(row[60]),
                            SkillsDevelopmentStaffPossibleValues: parseAndConvert(row[61]),
                            HRSupplierScoreValues: parseAndConvert(row[62]),
                            HRSupplierPossibleValues: parseAndConvert(row[63]),
                            HRStaffScoreValues: parseAndConvert(row[64]),
                            HRStaffPossibleValues: parseAndConvert(row[65]),
                            SocialInvestmentSupplierScoreValues: parseAndConvert(row[66]),
                            SocialInvestmentSupplierPossibleValues: parseAndConvert(row[67]),
                            SocialInvestmentStaffScoreValues: parseAndConvert(row[68]),
                            SocialInvestmentStaffPossibleValues: parseAndConvert(row[69]),
                            ProcurementSupplierScoreValues: parseAndConvert(row[70]),
                            ProcurementSupplierPossibleValues: parseAndConvert(row[71]),
                            ProcurementStaffScoreValues: parseAndConvert(row[72]),
                            ProcurementStaffPossibleValues: parseAndConvert(row[73]),
                            HealthSupplierScoreValues: parseAndConvert(row[74]),
                            HealthSupplierPossibleValues: parseAndConvert(row[75]),
                            HealthStaffScoreValues: parseAndConvert(row[76]),
                            HealthStaffPossibleValues: parseAndConvert(row[77]),
                            EnvironmentSupplierScoreValues: parseAndConvert(row[78]),
                            EnvironmentSupplierPossibleValues: parseAndConvert(row[79]),
                            EnvironmentStaffScoreValues: parseAndConvert(row[80]),
                            EnvironmentStaffPossibleValues: parseAndConvert(row[81]),
                            WorkplaceSupplierScoreValues: parseAndConvert(row[82]),
                            WorkplaceSupplierPossibleValues: parseAndConvert(row[83]),
                            WorkplaceStaffScoreValues: parseAndConvert(row[84]),
                            WorkplaceStaffPossibleValues: parseAndConvert(row[85]),
                            HIVSupplierScoreValues: parseAndConvert(row[86]),
                            HIVSupplierPossibleValues: parseAndConvert(row[87]),
                            HIVStaffScoreValues: parseAndConvert(row[88]),
                            HIVStaffPossibleValues: parseAndConvert(row[89]),
                            CompanyInfo: row[90],
                            CompanyFixes: row[91],
                            CompanyPositives: row[92],
                            CloutSASalesPercentage: row[93],
                            Ownership: row[94],
                            Employees: row[95]


                        };
                        companies_array.push(company);
                    }
                }

                setCompanies(companies_array);


                // setLoading(false);

                return data;
            })
            .then((data) => {

                // parse the data into the category model

                // parse the data into the category model
                let categories_array: Category[] = [];
                let rows = data.values;

                //drop the first row
                rows.shift();

                // only use rows where row[0] = companyType
                rows = rows.filter((row: any) => row[0] === companyType);

                // add the following categories: Legal	Ownership	Equity	Skills Development	HR	Social Investment	Procurement	Health	Environment	Workplace	HIV 

                // let legalCategory = {
                //     CategoryName: "Legal",
                //     // set Companies to an array of the first column
                //     Companies: rows.map((row: any) => row[1]),
                //     CoreCompliance : rows.map((row: any) => row[3]),
                //     ValuesAlignment : rows.map((row: any) => row[4])
                // }

                // let ownershipCategory = {
                //     CategoryName: "Ownership",
                //     // set Companies to an array of the first column
                //     Companies: rows.map((row: any) => row[1]),
                //     CoreCompliance : rows.map((row: any) => row[5]),
                //     ValuesAlignment : rows.map((row: any) => row[6])
                // }

                // let equityCategory = {
                //     CategoryName: "Equity",
                //     // set Companies to an array of the first column
                //     Companies: rows.map((row: any) => row[1]),
                //     CoreCompliance : rows.map((row: any) => row[7]),
                //     ValuesAlignment : rows.map((row: any) => row[8])
                // }

                // let skillsCategory = {
                //     CategoryName: "Skills Development",
                //     // set Companies to an array of the first column
                //     Companies: rows.map((row: any) => row[1]),
                //     CoreCompliance : rows.map((row: any) => row[9]),
                //     ValuesAlignment : rows.map((row: any) => row[10])
                // }

                // let hrCategory = {
                //     CategoryName: "HR",
                //     // set Companies to an array of the first column
                //     Companies: rows.map((row: any) => row[1]),
                //     CoreCompliance : rows.map((row: any) => row[11]),
                //     ValuesAlignment : rows.map((row: any) => row[12])
                // }

                // let socialCategory = {
                //     CategoryName: "Social Investment",
                //     // set Companies to an array of the first column
                //     Companies: rows.map((row: any) => row[1]),
                //     CoreCompliance : rows.map((row: any) => row[13]),
                //     ValuesAlignment : rows.map((row: any) => row[14])
                // }

                // let procurementCategory = {
                //     CategoryName: "Procurement",
                //     // set Companies to an array of the first column
                //     Companies: rows.map((row: any) => row[1]),
                //     CoreCompliance : rows.map((row: any) => row[15]),
                //     ValuesAlignment : rows.map((row: any) => row[16])
                // }

                // let healthCategory = {
                //     CategoryName: "Health",
                //     // set Companies to an array of the first column
                //     Companies: rows.map((row: any) => row[1]),
                //     CoreCompliance : rows.map((row: any) => row[17]),
                //     ValuesAlignment : rows.map((row: any) => row[18])
                // }

                // let environmentCategory = {
                //     CategoryName: "Environment",
                //     // set Companies to an array of the first column
                //     Companies: rows.map((row: any) => row[1]),
                //     CoreCompliance : rows.map((row: any) => row[19]),
                //     ValuesAlignment : rows.map((row: any) => row[20])
                // }

                // let workplaceCategory = {
                //     CategoryName: "Workplace",
                //     // set Companies to an array of the first column
                //     Companies: rows.map((row: any) => row[1]),
                //     CoreCompliance : rows.map((row: any) => row[21]),
                //     ValuesAlignment : rows.map((row: any) => row[22])
                // }

                // let hivCategory = {
                //     CategoryName: "HIV",
                //     // set Companies to an array of the first column
                //     Companies: rows.map((row: any) => row[1]),
                //     CoreCompliance : rows.map((row: any) => row[23]),
                //     ValuesAlignment : rows.map((row: any) => row[24])
                // }


                // categories_array.push(legalCategory);
                // categories_array.push(ownershipCategory);
                // categories_array.push(equityCategory);
                // categories_array.push(skillsCategory);
                // categories_array.push(hrCategory);
                // categories_array.push(socialCategory);
                // categories_array.push(procurementCategory);
                // categories_array.push(healthCategory);
                // categories_array.push(environmentCategory);
                // categories_array.push(workplaceCategory);
                // categories_array.push(hivCategory);

                // setCategories(categories_array);


                let averageCategory = {
                    CategoryName: "Average per Company",
                    // set Companies to an array of the first column
                    Companies: rows.map((row: any) => row[1]),
                    //set CoreCompliance to the average of all the core compliance columns
                    CoreCompliance :  rows.map((row: any) => (parseAndConvert(row[2]) + parseAndConvert(row[4]) + parseAndConvert(row[6]) + parseAndConvert(row[8]) + parseAndConvert(row[10]) + parseAndConvert(row[12]) + parseAndConvert(row[14]) + parseAndConvert(row[16]) + parseAndConvert(row[18]) + parseAndConvert(row[20]) + parseAndConvert(row[22]) + parseAndConvert(row[24]) + parseAndConvert(row[26]) + parseAndConvert(row[28]) + parseAndConvert(row[30]) + parseAndConvert(row[32]) + parseAndConvert(row[34]) + parseAndConvert(row[36]) + parseAndConvert(row[38]) + parseAndConvert(row[40]) + parseAndConvert(row[42]) + parseAndConvert(row[44]) 
                    
                    ) / (parseAndConvert(row[3]) + parseAndConvert(row[5]) + parseAndConvert(row[7]) + parseAndConvert(row[9]) + parseAndConvert(row[11]) + parseAndConvert(row[13]) + parseAndConvert(row[15]) + parseAndConvert(row[17]) + parseAndConvert(row[19]) + parseAndConvert(row[21]) + parseAndConvert(row[23]) + parseAndConvert(row[25]) + parseAndConvert(row[27]) + parseAndConvert(row[29]) + parseAndConvert(row[31]) + parseAndConvert(row[33]) + parseAndConvert(row[35]) + parseAndConvert(row[37]) + parseAndConvert(row[39]) + parseAndConvert(row[41]) + parseAndConvert(row[43]) + parseAndConvert(row[45])   ) * 100),
                    
                    // do the same for ValuesAlignment
                    ValuesAlignment : rows.map((row: any) => (parseAndConvert(row[46]) + parseAndConvert(row[48]) + parseAndConvert(row[50]) + parseAndConvert(row[52]) + parseAndConvert(row[54]) + parseAndConvert(row[56]) + parseAndConvert(row[58]) + parseAndConvert(row[60]) + parseAndConvert(row[62]) + parseAndConvert(row[64]) + parseAndConvert(row[66]) + parseAndConvert(row[68]) + parseAndConvert(row[70]) + parseAndConvert(row[72]) + parseAndConvert(row[74]) + parseAndConvert(row[76]) + parseAndConvert(row[78]) + parseAndConvert(row[80]) + parseAndConvert(row[82]) + parseAndConvert(row[84]) + parseAndConvert(row[86]) + parseAndConvert(row[88])

                    ) / (parseAndConvert(row[47]) + parseAndConvert(row[49]) + parseAndConvert(row[51]) + parseAndConvert(row[53]) + parseAndConvert(row[55]) + parseAndConvert(row[57]) + parseAndConvert(row[59]) + parseAndConvert(row[61]) + parseAndConvert(row[63]) + parseAndConvert(row[65]) + parseAndConvert(row[67]) + parseAndConvert(row[69]) + parseAndConvert(row[71]) + parseAndConvert(row[73]) + parseAndConvert(row[75]) + parseAndConvert(row[77]) + parseAndConvert(row[79]) + parseAndConvert(row[81]) + parseAndConvert(row[83]) + parseAndConvert(row[85]) + parseAndConvert(row[87]) + parseAndConvert(row[89]) ) * 100)



                }


                
                // console.log('begin v')
                // console.log(averageCategory)
                // console.log('end ^')



                setCategoriesAverage([averageCategory]);

                setLoading(false);

                })
            .catch((error) => {
                setError(error);
                setLoading(false);
            });
    }, [GOOGLE_API_KEY, GOOGLE_SHEET, GOOGLE_SHEET_TAB, companyType]);

    useEffect(() => {


        const averageCompany = {
            Name: "Average per Category",
            Size: "All",
            AssessmentType: "All",

            LegalSupplierScoreCore: companies.map((company: DetailedCompany) => company.LegalSupplierScoreCore).reduce((a: number, b: number) => a + b, 0),
            LegalSupplierPossibleCore: companies.map((company: DetailedCompany) => company.LegalSupplierPossibleCore).reduce((a: number, b: number) => a + b, 0),
            LegalStaffScoreCore: companies.map((company: DetailedCompany) => company.LegalStaffScoreCore).reduce((a: number, b: number) => a + b, 0),
            LegalStaffPossibleCore: companies.map((company: DetailedCompany) => company.LegalStaffPossibleCore).reduce((a: number, b: number) => a + b, 0),
            OwnershipSupplierScoreCore: companies.map((company: DetailedCompany) => company.OwnershipSupplierScoreCore).reduce((a: number, b: number) => a + b, 0),
            OwnershipSupplierPossibleCore: companies.map((company: DetailedCompany) => company.OwnershipSupplierPossibleCore).reduce((a: number, b: number) => a + b, 0),
            OwnershipStaffScoreCore: companies.map((company: DetailedCompany) => company.OwnershipStaffScoreCore).reduce((a: number, b: number) => a + b, 0),
            OwnershipStaffPossibleCore: companies.map((company: DetailedCompany) => company.OwnershipStaffPossibleCore).reduce((a: number, b: number) => a + b, 0),
            EquitySupplierScoreCore: companies.map((company: DetailedCompany) => company.EquitySupplierScoreCore).reduce((a: number, b: number) => a + b, 0),
            EquitySupplierPossibleCore: companies.map((company: DetailedCompany) => company.EquitySupplierPossibleCore).reduce((a: number, b: number) => a + b, 0),
            EquityStaffScoreCore: companies.map((company: DetailedCompany) => company.EquityStaffScoreCore).reduce((a: number, b: number) => a + b, 0),
            EquityStaffPossibleCore: companies.map((company: DetailedCompany) => company.EquityStaffPossibleCore).reduce((a: number, b: number) => a + b, 0),
            SkillsDevelopmentSupplierScoreCore: companies.map((company: DetailedCompany) => company.SkillsDevelopmentSupplierScoreCore).reduce((a: number, b: number) => a + b, 0),
            SkillsDevelopmentSupplierPossibleCore: companies.map((company: DetailedCompany) => company.SkillsDevelopmentSupplierPossibleCore).reduce((a: number, b: number) => a + b, 0),
            SkillsDevelopmentStaffScoreCore: companies.map((company: DetailedCompany) => company.SkillsDevelopmentStaffScoreCore).reduce((a: number, b: number) => a + b, 0),
            SkillsDevelopmentStaffPossibleCore: companies.map((company: DetailedCompany) => company.SkillsDevelopmentStaffPossibleCore).reduce((a: number, b: number) => a + b, 0),
            HRSupplierScoreCore: companies.map((company: DetailedCompany) => company.HRSupplierScoreCore).reduce((a: number, b: number) => a + b, 0),
            HRSupplierPossibleCore: companies.map((company: DetailedCompany) => company.HRSupplierPossibleCore).reduce((a: number, b: number) => a + b, 0),
            HRStaffScoreCore: companies.map((company: DetailedCompany) => company.HRStaffScoreCore).reduce((a: number, b: number) => a + b, 0),
            HRStaffPossibleCore: companies.map((company: DetailedCompany) => company.HRStaffPossibleCore).reduce((a: number, b: number) => a + b, 0),
            SocialInvestmentSupplierScoreCore: companies.map((company: DetailedCompany) => company.SocialInvestmentSupplierScoreCore).reduce((a: number, b: number) => a + b, 0),
            SocialInvestmentSupplierPossibleCore: companies.map((company: DetailedCompany) => company.SocialInvestmentSupplierPossibleCore).reduce((a: number, b: number) => a + b, 0),
            SocialInvestmentStaffScoreCore: companies.map((company: DetailedCompany) => company.SocialInvestmentStaffScoreCore).reduce((a: number, b: number) => a + b, 0),
            SocialInvestmentStaffPossibleCore: companies.map((company: DetailedCompany) => company.SocialInvestmentStaffPossibleCore).reduce((a: number, b: number) => a + b, 0),
            ProcurementSupplierScoreCore: companies.map((company: DetailedCompany) => company.ProcurementSupplierScoreCore).reduce((a: number, b: number) => a + b, 0),
            ProcurementSupplierPossibleCore: companies.map((company: DetailedCompany) => company.ProcurementSupplierPossibleCore).reduce((a: number, b: number) => a + b, 0),
            ProcurementStaffScoreCore: companies.map((company: DetailedCompany) => company.ProcurementStaffScoreCore).reduce((a: number, b: number) => a + b, 0),
            ProcurementStaffPossibleCore: companies.map((company: DetailedCompany) => company.ProcurementStaffPossibleCore).reduce((a: number, b: number) => a + b, 0),
            HealthSupplierScoreCore: companies.map((company: DetailedCompany) => company.HealthSupplierScoreCore).reduce((a: number, b: number) => a + b, 0),
            HealthSupplierPossibleCore: companies.map((company: DetailedCompany) => company.HealthSupplierPossibleCore).reduce((a: number, b: number) => a + b, 0),
            HealthStaffScoreCore: companies.map((company: DetailedCompany) => company.HealthStaffScoreCore).reduce((a: number, b: number) => a + b, 0),
            HealthStaffPossibleCore: companies.map((company: DetailedCompany) => company.HealthStaffPossibleCore).reduce((a: number, b: number) => a + b, 0),
            EnvironmentSupplierScoreCore: companies.map((company: DetailedCompany) => company.EnvironmentSupplierScoreCore).reduce((a: number, b: number) => a + b, 0),
            EnvironmentSupplierPossibleCore: companies.map((company: DetailedCompany) => company.EnvironmentSupplierPossibleCore).reduce((a: number, b: number) => a + b, 0),
            EnvironmentStaffScoreCore: companies.map((company: DetailedCompany) => company.EnvironmentStaffScoreCore).reduce((a: number, b: number) => a + b, 0),
            EnvironmentStaffPossibleCore: companies.map((company: DetailedCompany) => company.EnvironmentStaffPossibleCore).reduce((a: number, b: number) => a + b, 0),
            WorkplaceSupplierScoreCore: companies.map((company: DetailedCompany) => company.WorkplaceSupplierScoreCore).reduce((a: number, b: number) => a + b, 0),
            WorkplaceSupplierPossibleCore: companies.map((company: DetailedCompany) => company.WorkplaceSupplierPossibleCore).reduce((a: number, b: number) => a + b, 0),
            WorkplaceStaffScoreCore: companies.map((company: DetailedCompany) => company.WorkplaceStaffScoreCore).reduce((a: number, b: number) => a + b, 0),
            WorkplaceStaffPossibleCore: companies.map((company: DetailedCompany) => company.WorkplaceStaffPossibleCore).reduce((a: number, b: number) => a + b, 0),
            HIVSupplierScoreCore: companies.map((company: DetailedCompany) => company.HIVSupplierScoreCore).reduce((a: number, b: number) => a + b, 0),
            HIVSupplierPossibleCore: companies.map((company: DetailedCompany) => company.HIVSupplierPossibleCore).reduce((a: number, b: number) => a + b, 0),
            HIVStaffScoreCore: companies.map((company: DetailedCompany) => company.HIVStaffScoreCore).reduce((a: number, b: number) => a + b, 0),
            HIVStaffPossibleCore: companies.map((company: DetailedCompany) => company.HIVStaffPossibleCore).reduce((a: number, b: number) => a + b, 0),



            LegalSupplierScoreValues: companies.map((company: DetailedCompany) => company.LegalSupplierScoreValues).reduce((a: number, b: number) => a + b, 0),
            LegalSupplierPossibleValues: companies.map((company: DetailedCompany) => company.LegalSupplierPossibleValues).reduce((a: number, b: number) => a + b, 0),
            LegalStaffScoreValues: companies.map((company: DetailedCompany) => company.LegalStaffScoreValues).reduce((a: number, b: number) => a + b, 0),
            LegalStaffPossibleValues: companies.map((company: DetailedCompany) => company.LegalStaffPossibleValues).reduce((a: number, b: number) => a + b, 0),
            OwnershipSupplierScoreValues: companies.map((company: DetailedCompany) => company.OwnershipSupplierScoreValues).reduce((a: number, b: number) => a + b, 0),
            OwnershipSupplierPossibleValues: companies.map((company: DetailedCompany) => company.OwnershipSupplierPossibleValues).reduce((a: number, b: number) => a + b, 0),
            OwnershipStaffScoreValues: companies.map((company: DetailedCompany) => company.OwnershipStaffScoreValues).reduce((a: number, b: number) => a + b, 0),
            OwnershipStaffPossibleValues: companies.map((company: DetailedCompany) => company.OwnershipStaffPossibleValues).reduce((a: number, b: number) => a + b, 0),
            EquitySupplierScoreValues: companies.map((company: DetailedCompany) => company.EquitySupplierScoreValues).reduce((a: number, b: number) => a + b, 0),
            EquitySupplierPossibleValues: companies.map((company: DetailedCompany) => company.EquitySupplierPossibleValues).reduce((a: number, b: number) => a + b, 0),
            EquityStaffScoreValues: companies.map((company: DetailedCompany) => company.EquityStaffScoreValues).reduce((a: number, b: number) => a + b, 0),
            EquityStaffPossibleValues: companies.map((company: DetailedCompany) => company.EquityStaffPossibleValues).reduce((a: number, b: number) => a + b, 0),
            SkillsDevelopmentSupplierScoreValues: companies.map((company: DetailedCompany) => company.SkillsDevelopmentSupplierScoreValues).reduce((a: number, b: number) => a + b, 0),
            SkillsDevelopmentSupplierPossibleValues: companies.map((company: DetailedCompany) => company.SkillsDevelopmentSupplierPossibleValues).reduce((a: number, b: number) => a + b, 0),
            SkillsDevelopmentStaffScoreValues: companies.map((company: DetailedCompany) => company.SkillsDevelopmentStaffScoreValues).reduce((a: number, b: number) => a + b, 0),
            SkillsDevelopmentStaffPossibleValues: companies.map((company: DetailedCompany) => company.SkillsDevelopmentStaffPossibleValues).reduce((a: number, b: number) => a + b, 0),
            HRSupplierScoreValues: companies.map((company: DetailedCompany) => company.HRSupplierScoreValues).reduce((a: number, b: number) => a + b, 0),
            HRSupplierPossibleValues: companies.map((company: DetailedCompany) => company.HRSupplierPossibleValues).reduce((a: number, b: number) => a + b, 0),
            HRStaffScoreValues: companies.map((company: DetailedCompany) => company.HRStaffScoreValues).reduce((a: number, b: number) => a + b, 0),
            HRStaffPossibleValues: companies.map((company: DetailedCompany) => company.HRStaffPossibleValues).reduce((a: number, b: number) => a + b, 0),
            SocialInvestmentSupplierScoreValues: companies.map((company: DetailedCompany) => company.SocialInvestmentSupplierScoreValues).reduce((a: number, b: number) => a + b, 0),
            SocialInvestmentSupplierPossibleValues: companies.map((company: DetailedCompany) => company.SocialInvestmentSupplierPossibleValues).reduce((a: number, b: number) => a + b, 0),
            SocialInvestmentStaffScoreValues: companies.map((company: DetailedCompany) => company.SocialInvestmentStaffScoreValues).reduce((a: number, b: number) => a + b, 0),
            SocialInvestmentStaffPossibleValues: companies.map((company: DetailedCompany) => company.SocialInvestmentStaffPossibleValues).reduce((a: number, b: number) => a + b, 0),
            ProcurementSupplierScoreValues: companies.map((company: DetailedCompany) => company.ProcurementSupplierScoreValues).reduce((a: number, b: number) => a + b, 0),
            ProcurementSupplierPossibleValues: companies.map((company: DetailedCompany) => company.ProcurementSupplierPossibleValues).reduce((a: number, b: number) => a + b, 0),
            ProcurementStaffScoreValues: companies.map((company: DetailedCompany) => company.ProcurementStaffScoreValues).reduce((a: number, b: number) => a + b, 0),
            ProcurementStaffPossibleValues: companies.map((company: DetailedCompany) => company.ProcurementStaffPossibleValues).reduce((a: number, b: number) => a + b, 0),
            HealthSupplierScoreValues: companies.map((company: DetailedCompany) => company.HealthSupplierScoreValues).reduce((a: number, b: number) => a + b, 0),
            HealthSupplierPossibleValues: companies.map((company: DetailedCompany) => company.HealthSupplierPossibleValues).reduce((a: number, b: number) => a + b, 0),
            HealthStaffScoreValues: companies.map((company: DetailedCompany) => company.HealthStaffScoreValues).reduce((a: number, b: number) => a + b, 0),
            HealthStaffPossibleValues: companies.map((company: DetailedCompany) => company.HealthStaffPossibleValues).reduce((a: number, b: number) => a + b, 0),
            EnvironmentSupplierScoreValues: companies.map((company: DetailedCompany) => company.EnvironmentSupplierScoreValues).reduce((a: number, b: number) => a + b, 0),
            EnvironmentSupplierPossibleValues: companies.map((company: DetailedCompany) => company.EnvironmentSupplierPossibleValues).reduce((a: number, b: number) => a + b, 0),
            EnvironmentStaffScoreValues: companies.map((company: DetailedCompany) => company.EnvironmentStaffScoreValues).reduce((a: number, b: number) => a + b, 0),
            EnvironmentStaffPossibleValues: companies.map((company: DetailedCompany) => company.EnvironmentStaffPossibleValues).reduce((a: number, b: number) => a + b, 0),
            WorkplaceSupplierScoreValues: companies.map((company: DetailedCompany) => company.WorkplaceSupplierScoreValues).reduce((a: number, b: number) => a + b, 0),
            WorkplaceSupplierPossibleValues: companies.map((company: DetailedCompany) => company.WorkplaceSupplierPossibleValues).reduce((a: number, b: number) => a + b, 0),
            WorkplaceStaffScoreValues: companies.map((company: DetailedCompany) => company.WorkplaceStaffScoreValues).reduce((a: number, b: number) => a + b, 0),
            WorkplaceStaffPossibleValues: companies.map((company: DetailedCompany) => company.WorkplaceStaffPossibleValues).reduce((a: number, b: number) => a + b, 0),
            HIVSupplierScoreValues: companies.map((company: DetailedCompany) => company.HIVSupplierScoreValues).reduce((a: number, b: number) => a + b, 0),
            HIVSupplierPossibleValues: companies.map((company: DetailedCompany) => company.HIVSupplierPossibleValues).reduce((a: number, b: number) => a + b, 0),
            HIVStaffScoreValues: companies.map((company: DetailedCompany) => company.HIVStaffScoreValues).reduce((a: number, b: number) => a + b, 0),
            HIVStaffPossibleValues: companies.map((company: DetailedCompany) => company.HIVStaffPossibleValues).reduce((a: number, b: number) => a + b, 0),








            CompanyInfo: "All",
            CompanyFixes: "All",
            CompanyPositives: "All",
            CloutSASalesPercentage: "All",
            Ownership: "All",
            Employees: "All"

        };
        setCompaniesAverage([averageCompany]);

}, [companies]);

const handleChange = (event: SelectChangeEvent) => {
        setCompanyType(event.target.value);
  };

    return (
        <div>

            <Grid container 
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={2}>

                <Grid item xs={12}>
                    <Typography variant="h2" component="h2" color={'#000000'} marginBottom={'10px'}><b>Overview Dashboards</b></Typography>
                    {loading && <p>Loading...</p>}
                    {error && <p>{error}</p>}
                </Grid>

                <Grid item xs={12} md={6}>
            <Typography variant="h5" component="h5" color={'#000000'} marginTop={'10px'}><b>Choose</b> Year</Typography>
            </Grid>


                <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Year</InputLabel>
              <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value="1"
              label="Year"
              onChange={handleChange}
              sx={{bgcolor:"#ffffff", height: "55px" }}
              
              >

                    <MenuItem value="1">2023</MenuItem>


              </Select>
            </FormControl>
            </Grid>



            <Grid item xs={12} md={6}>
            <Typography variant="h5" component="h5" color={'#000000'} marginTop={'10px'}><b>Choose</b> Company Size</Typography>
            </Grid>

            <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Company Size</InputLabel>
              <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={companyType}
              label="Company Size"
              onChange={handleChange}
              sx={{bgcolor:"#ffffff", height: "55px" }}
              
              >

                    <MenuItem value="Sole Proprietor">Sole Proprietor</MenuItem>
                    <MenuItem value="5 and under">5 and under</MenuItem>
                    <MenuItem value="6 or more">6 or more</MenuItem>


              </Select>
            </FormControl>
            </Grid>

            <Grid item xs={12}>
                    <hr />
                </Grid>



                {categoriesAverage.map((category) => (

                <Grid item  xs={12} sm={12} md={6} lg={6} xl={4}>
                    
                    <Typography variant="h4" component="h4" color={'#000000'} marginBottom={'10px'}><b>{category.CategoryName}</b></Typography>
                    This chart shows the average score per company for all the categories.
                    <CategoryGraph category={category} />
                </Grid> 



                    ))}




                    {companiesAverage.map((company) => (
                        <>

                    <Grid item  xs={12} sm={12} md={6} lg={6} xl={4}>
                        
                        <Typography variant="h4" component="h4" color={'#000000'} marginBottom={'10px'}><b>{company.Name}</b></Typography>
                        This chart shows the average score per category for all the companies.
                        <DetailedCompanyGraph company={company} />
                    </Grid> 

                    {companyType !== "Sole Proprietor" && (

                    <Grid item  xs={12} sm={12} md={6} lg={6} xl={4}>
                                            
                    <Typography variant="h4" component="h4" color={'#000000'} marginBottom={'10px'}><b>Supplier vs Staff Analysis</b></Typography>
                    This chart shows the average score by supplier vs staff for all the companies.
                    <SupplierStaffCompanyGraph company={company} />
                    </Grid> 
                    )}

                    </>



                    ))}


{/* 
                <Grid item xs={12}>
                    <Typography variant="h2" component="h2" color={'#000000'} marginBottom={'10px'}><b>Companies</b></Typography>
                    {loading && <p>Loading...</p>}
                    {error && <p>{error}</p>}
                </Grid>

                {companies.map((company) => (

                    <Grid item  xs={12} sm={12} md={6} lg={6} xl={4}>
                        
                        <Typography variant="h4" component="h4" color={'#000000'} marginBottom={'10px'}><b>{company.Name}</b></Typography>
                        <CompanyGraph company={company} />
                    </Grid> 



                ))}
                                <Grid item xs={12}>
                    <Typography variant="h2" component="h2" color={'#000000'} marginBottom={'10px'}><b>Categories</b></Typography>
                    {loading && <p>Loading...</p>}
                    {error && <p>{error}</p>}
                </Grid>

                {categories.map((category) => (

                    <Grid item  xs={12} sm={12} md={6} lg={6} xl={4}>
                        <Typography variant="h4" component="h4" color={'#000000'} marginBottom={'10px'}><b>{category.CategoryName}</b></Typography>
                        <CategoryGraph category={category} />
                    </Grid> 

                    ))}

                     */}
            </Grid>

        </div>
    )
};

export default Dashboard;