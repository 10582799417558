// display graph

// Path: src/Components/CompanyGraph.tsx

import React from "react";
import {Typography, Grid, Box} from '@mui/material';
import { Company } from "../Models/Company";
import { DetailedCompany } from "../Models/DetailedCompany";
import { Bar } from 'react-chartjs-2';
import { Paper } from '@mui/material';
import ChartDataLabels from "chartjs-plugin-datalabels";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    LineElement,
    PointElement,
    RadialLinearScale
  } from 'chart.js';
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";

function SupplierStaffCompanyGraph(props: {company: DetailedCompany}): JSX.Element {

    ChartJS.register(ChartDataLabels);

    // Change default options for ALL charts
    ChartJS.defaults.set('plugins.datalabels', {
        color: '#ffffff',
        anchor: "end",
        align: "start",
    });

    const [labels, setLabels] = useState<string[]>([]);
    const [supplierData, setSupplierData] = useState<string[]>([]);
    const [staffData, setStaffData] = useState<string[]>([]);

    useEffect(() => {


        let this_labels = [];
        let this_supplierData = [];
        let this_staffData = [];


        let LegalScoreSupplier = props.company.LegalSupplierScoreCore + props.company.LegalSupplierScoreValues;
        let LegalPossibleSupplier = props.company.LegalSupplierPossibleCore + props.company.LegalSupplierPossibleValues;
        let LegalScoreStaff = props.company.LegalStaffScoreCore + props.company.LegalStaffScoreValues;
        let LegalPossibleStaff = props.company.LegalStaffPossibleCore + props.company.LegalStaffPossibleValues;
        let LegalPossible = LegalPossibleSupplier + LegalPossibleStaff;

        if (LegalPossible > 0) {
            if (LegalPossibleSupplier > 0) {
                this_supplierData.push(Math.round((LegalScoreSupplier / LegalPossibleSupplier) * 100).toString());
            }
            else{
                this_supplierData.push('n/a');
            }
            if (LegalPossibleStaff > 0) {
                this_staffData.push(Math.round((LegalScoreStaff / LegalPossibleStaff) * 100).toString());
            }
            else{
                this_staffData.push('n/a');
            }
            this_labels.push('Legal');
        }

        let OwnershipScoreSupplier = props.company.OwnershipSupplierScoreCore + props.company.OwnershipSupplierScoreValues;
        let OwnershipPossibleSupplier = props.company.OwnershipSupplierPossibleCore + props.company.OwnershipSupplierPossibleValues;
        let OwnershipScoreStaff = props.company.OwnershipStaffScoreCore + props.company.OwnershipStaffScoreValues;
        let OwnershipPossibleStaff = props.company.OwnershipStaffPossibleCore + props.company.OwnershipStaffPossibleValues;
        let OwnershipPossible = OwnershipPossibleSupplier + OwnershipPossibleStaff;
        if (OwnershipPossible > 0) {
            if (OwnershipPossibleSupplier > 0) {
                this_supplierData.push(Math.round((OwnershipScoreSupplier / OwnershipPossibleSupplier) * 100).toString());
            }
            else{this_supplierData.push('n/a');}
            if (OwnershipPossibleStaff > 0) {
                this_staffData.push(Math.round((OwnershipScoreStaff / OwnershipPossibleStaff) * 100).toString());
            }
            else{this_staffData.push('n/a');}
            this_labels.push('Ownership');
        }

        let EquityScoreSupplier = props.company.EquitySupplierScoreCore + props.company.EquitySupplierScoreValues;
        let EquityPossibleSupplier = props.company.EquitySupplierPossibleCore + props.company.EquitySupplierPossibleValues;
        let EquityScoreStaff = props.company.EquityStaffScoreCore + props.company.EquityStaffScoreValues;
        let EquityPossibleStaff = props.company.EquityStaffPossibleCore + props.company.EquityStaffPossibleValues;
        let EquityPossible = EquityPossibleSupplier + EquityPossibleStaff;
        if (EquityPossible > 0) {
            if (EquityPossibleSupplier > 0) {
                this_supplierData.push(Math.round((EquityScoreSupplier / EquityPossibleSupplier) * 100).toString());
            }
            else{this_supplierData.push('n/a');}
            if (EquityPossibleStaff > 0) {
                this_staffData.push(Math.round((EquityScoreStaff / EquityPossibleStaff) * 100).toString());
            }
            else{this_staffData.push('n/a');}
            this_labels.push('Equity');
        }

        //SkillsDevelopment
        let SkillsDevelopmentScoreSupplier = props.company.SkillsDevelopmentSupplierScoreCore + props.company.SkillsDevelopmentSupplierScoreValues;
        let SkillsDevelopmentPossibleSupplier = props.company.SkillsDevelopmentSupplierPossibleCore + props.company.SkillsDevelopmentSupplierPossibleValues;
        let SkillsDevelopmentScoreStaff = props.company.SkillsDevelopmentStaffScoreCore + props.company.SkillsDevelopmentStaffScoreValues;
        let SkillsDevelopmentPossibleStaff = props.company.SkillsDevelopmentStaffPossibleCore + props.company.SkillsDevelopmentStaffPossibleValues;
        let SkillsDevelopmentPossible = SkillsDevelopmentPossibleSupplier + SkillsDevelopmentPossibleStaff;
        if (SkillsDevelopmentPossible > 0) {
            if (SkillsDevelopmentPossibleSupplier > 0) {
                this_supplierData.push(Math.round((SkillsDevelopmentScoreSupplier / SkillsDevelopmentPossibleSupplier) * 100).toString());
            }
            else{this_supplierData.push('n/a');}
            if (SkillsDevelopmentPossibleStaff > 0) {
                this_staffData.push(Math.round((SkillsDevelopmentScoreStaff / SkillsDevelopmentPossibleStaff) * 100).toString());
            }
            else{this_staffData.push('n/a');}
            this_labels.push('Skills Development');
        }

        // HR
        let HRScoreSupplier = props.company.HRSupplierScoreCore + props.company.HRSupplierScoreValues;
        let HRPossibleSupplier = props.company.HRSupplierPossibleCore + props.company.HRSupplierPossibleValues;
        let HRScoreStaff = props.company.HRStaffScoreCore + props.company.HRStaffScoreValues;
        let HRPossibleStaff = props.company.HRStaffPossibleCore + props.company.HRStaffPossibleValues;
        let HRPossible = HRPossibleSupplier + HRPossibleStaff;
        if (HRPossible > 0) {
            if (HRPossibleSupplier > 0) {
                this_supplierData.push(Math.round((HRScoreSupplier / HRPossibleSupplier) * 100).toString());
            }
            else{this_supplierData.push('n/a');}
            if (HRPossibleStaff > 0) {
                this_staffData.push(Math.round((HRScoreStaff / HRPossibleStaff) * 100).toString());
            }
            else{this_staffData.push('n/a');}
            this_labels.push('HR');
        }

        // SocialInvestment
        let SocialInvestmentScoreSupplier = props.company.SocialInvestmentSupplierScoreCore + props.company.SocialInvestmentSupplierScoreValues;
        let SocialInvestmentPossibleSupplier = props.company.SocialInvestmentSupplierPossibleCore + props.company.SocialInvestmentSupplierPossibleValues;
        let SocialInvestmentScoreStaff = props.company.SocialInvestmentStaffScoreCore + props.company.SocialInvestmentStaffScoreValues;
        let SocialInvestmentPossibleStaff = props.company.SocialInvestmentStaffPossibleCore + props.company.SocialInvestmentStaffPossibleValues;
        let SocialInvestmentPossible = SocialInvestmentPossibleSupplier + SocialInvestmentPossibleStaff;
        if (SocialInvestmentPossible > 0) {
            if (SocialInvestmentPossibleSupplier > 0) {
                this_supplierData.push(Math.round((SocialInvestmentScoreSupplier / SocialInvestmentPossibleSupplier) * 100).toString());
            }
            else{this_supplierData.push('n/a');}
            if (SocialInvestmentPossibleStaff > 0) {
                this_staffData.push(Math.round((SocialInvestmentScoreStaff / SocialInvestmentPossibleStaff) * 100).toString());
            }
            else{this_staffData.push('n/a');}
            this_labels.push('Social Investment');
        }

        // Procurement
        let ProcurementScoreSupplier = props.company.ProcurementSupplierScoreCore + props.company.ProcurementSupplierScoreValues;
        let ProcurementPossibleSupplier = props.company.ProcurementSupplierPossibleCore + props.company.ProcurementSupplierPossibleValues;
        let ProcurementScoreStaff = props.company.ProcurementStaffScoreCore + props.company.ProcurementStaffScoreValues;
        let ProcurementPossibleStaff = props.company.ProcurementStaffPossibleCore + props.company.ProcurementStaffPossibleValues;
        let ProcurementPossible = ProcurementPossibleSupplier + ProcurementPossibleStaff;
        if (ProcurementPossible > 0) {
            if (ProcurementPossibleSupplier > 0) {
                this_supplierData.push(Math.round((ProcurementScoreSupplier / ProcurementPossibleSupplier) * 100).toString());
            }
            else{this_supplierData.push('n/a');}
            if (ProcurementPossibleStaff > 0) {
                this_staffData.push(Math.round((ProcurementScoreStaff / ProcurementPossibleStaff) * 100).toString());
            }
            else{this_staffData.push('n/a');}
            this_labels.push('Procurement');
        }

        // Health
        let HealthScoreSupplier = props.company.HealthSupplierScoreCore + props.company.HealthSupplierScoreValues;
        let HealthPossibleSupplier = props.company.HealthSupplierPossibleCore + props.company.HealthSupplierPossibleValues;
        let HealthScoreStaff = props.company.HealthStaffScoreCore + props.company.HealthStaffScoreValues;
        let HealthPossibleStaff = props.company.HealthStaffPossibleCore + props.company.HealthStaffPossibleValues;
        let HealthPossible = HealthPossibleSupplier + HealthPossibleStaff;
        if (HealthPossible > 0) {
            if (HealthPossibleSupplier > 0) {
                this_supplierData.push(Math.round((HealthScoreSupplier / HealthPossibleSupplier) * 100).toString());
            }
            else{this_supplierData.push('n/a');}
            if (HealthPossibleStaff > 0) {
                this_staffData.push(Math.round((HealthScoreStaff / HealthPossibleStaff) * 100).toString());
            }
            else{this_staffData.push('n/a');}
            this_labels.push('Health');
        }

        // Environment
        let EnvironmentScoreSupplier = props.company.EnvironmentSupplierScoreCore + props.company.EnvironmentSupplierScoreValues;
        let EnvironmentPossibleSupplier = props.company.EnvironmentSupplierPossibleCore + props.company.EnvironmentSupplierPossibleValues;
        let EnvironmentScoreStaff = props.company.EnvironmentStaffScoreCore + props.company.EnvironmentStaffScoreValues;
        let EnvironmentPossibleStaff = props.company.EnvironmentStaffPossibleCore + props.company.EnvironmentStaffPossibleValues;
        let EnvironmentPossible = EnvironmentPossibleSupplier + EnvironmentPossibleStaff;
        if (EnvironmentPossible > 0) {
            if (EnvironmentPossibleSupplier > 0) {
                this_supplierData.push(Math.round((EnvironmentScoreSupplier / EnvironmentPossibleSupplier) * 100).toString());
            }
            else{this_supplierData.push('n/a');}
            if (EnvironmentPossibleStaff > 0) {
                this_staffData.push(Math.round((EnvironmentScoreStaff / EnvironmentPossibleStaff) * 100).toString());
            }
            else{this_staffData.push('n/a');}
            this_labels.push('Environment');
        }

        // Workplace
        let WorkplaceScoreSupplier = props.company.WorkplaceSupplierScoreCore + props.company.WorkplaceSupplierScoreValues;
        let WorkplacePossibleSupplier = props.company.WorkplaceSupplierPossibleCore + props.company.WorkplaceSupplierPossibleValues;
        let WorkplaceScoreStaff = props.company.WorkplaceStaffScoreCore + props.company.WorkplaceStaffScoreValues;
        let WorkplacePossibleStaff = props.company.WorkplaceStaffPossibleCore + props.company.WorkplaceStaffPossibleValues;
        let WorkplacePossible = WorkplacePossibleSupplier + WorkplacePossibleStaff;
        if (WorkplacePossible > 0) {
            if (WorkplacePossibleSupplier > 0) {
                this_supplierData.push(Math.round((WorkplaceScoreSupplier / WorkplacePossibleSupplier) * 100).toString());
            }
            else{this_supplierData.push('n/a');}
            if (WorkplacePossibleStaff > 0) {
                this_staffData.push(Math.round((WorkplaceScoreStaff / WorkplacePossibleStaff) * 100).toString());
            }
            else{this_staffData.push('n/a');}
            this_labels.push('Workplace');
        }

        // HIV
        let HIVScoreSupplier = props.company.HIVSupplierScoreCore + props.company.HIVSupplierScoreValues;
        let HIVPossibleSupplier = props.company.HIVSupplierPossibleCore + props.company.HIVSupplierPossibleValues;
        let HIVScoreStaff = props.company.HIVStaffScoreCore + props.company.HIVStaffScoreValues;
        let HIVPossibleStaff = props.company.HIVStaffPossibleCore + props.company.HIVStaffPossibleValues;
        let HIVPossible = HIVPossibleSupplier + HIVPossibleStaff;
        if (HIVPossible > 0) {
            if (HIVPossibleSupplier > 0) {
                this_supplierData.push(Math.round((HIVScoreSupplier / HIVPossibleSupplier) * 100).toString());
            }
            else{this_supplierData.push('n/a');}
            if (HIVPossibleStaff > 0) {
                this_staffData.push(Math.round((HIVScoreStaff / HIVPossibleStaff) * 100).toString());
            }
            else{this_staffData.push('n/a');}
            this_labels.push('HIV');
        }

        









        
        





        setLabels(this_labels);
        setSupplierData(this_supplierData);
        setStaffData(this_staffData);
    }, [props.company]);





    return (


                    <Paper elevation={0} sx={{
                        p: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        borderRadius: 2,
                        height:"60vh",
                        }}
                    >

                        <Bar
                            data={{
                                labels: labels,
                                datasets: [
                                    {
                                        label: 'Supplier',
                                        data: supplierData,
                                        borderColor: '#CE0929',
                                        backgroundColor: '#CE0929',
                                        borderWidth: 1,
                                    },
                                    {
                                        label: 'Staff',
                                        data: staffData,
                                        borderColor: '#FECD2C',
                                        backgroundColor: '#FECD2C',
                                        borderWidth: 1,
                                    },
                                ],
                            }}

                            options={
                                
                                {
                                    maintainAspectRatio: false,
                                    indexAxis: 'y' as const,
                                    elements: {
                                      bar: {
                                        borderWidth: 1,
                                      },
                                    },
                                    responsive: true,
                                    plugins: {
                                
                                      legend: {
                                        position: 'bottom' as const,
                                      },
                                      title: {
                                        display: false,
                                        text: '',
                                      },
                                      datalabels: {
                                          formatter: function(value:number) {
                                            
                                                //set the value to 1 decimal at most
                                                // return Math.round(value * 10) / 10 +'%';
                                                return Math.round(value) +'%';
                                          }
                                      }
                                    },
                                }
                            }
                        />
                    </Paper>


    );
}

export default SupplierStaffCompanyGraph;
