import React from "react";
import {Typography, Grid, Box} from '@mui/material';

function Description(): JSX.Element {

    return (
        <div>
            <Grid container 
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"

                spacing={3}
                sx={{ flexGrow: '1' }
            }
            >
                <Grid item xs={12}>
                <Typography variant="h2" component="h2" color={'#000000'} marginBottom={'10px'}><b>Navigation</b></Typography>
                </Grid>
                





                <Grid item xs={12} >
                    <p>
                    There are four main pages for analysis of the data.
                    </p>
                </Grid>


{/* 

                <Grid item xs={12} >
                    <Typography variant="h5" component="h5" color={'#000000'} marginBottom={'10px'}><b>Clout/SA</b> </Typography>
                </Grid>


                <Grid item xs={6} >
                    <p>
                    qwe

                    </p>
                </Grid>


                
                <Grid item xs={6} >
                    <Box
                        component="img"
                        sx={{
                        height: 200,
                        width: 400,
                        border: 1,
                        }}
                        src="/thumb_1.png"
                    />
                </Grid> */}



                <Grid item xs={12} >
                    <Typography variant="h5" component="h5" color={'#000000'} marginBottom={'10px'}><b>Overview Dashboards</b> </Typography>
                </Grid>


                
                <Grid item xs={6} >
                    <p>
                        The dashboards give a high level overview of the data.  The user can select the size of the companies to analyse, and then compare these charts:
                        <ul>
                        <li>Average per Company (where companies are compared to each other)
                        </li><li>Average per Category (where categories are compared to each other)
                        </li><li>Supplier vs Staff Analysis (where the scoring of the suppliers themselves are compared to the scoring of the staff)</li>
                        </ul>
                    </p>
                </Grid>


                
                <Grid item xs={6} >
                    <Box
                        component="img"
                        sx={{
                        height: 200,
                        width: 400,
                        border: 1,
                        }}
                        src="/thumb1.png"
                    />
                </Grid>





                <Grid item xs={12} >
                    <Typography variant="h5" component="h5" color={'#000000'} marginBottom={'10px'}><b>Company Details</b> </Typography>
                </Grid>

                
                <Grid item xs={6} >
                    <p>
                        On the company details page, the user can select an individual company, and get all the information regarding that company, including contact detail, ownership details, areas for improvement as well as some charts analysing the company's data.
                    </p>
                </Grid>


                
                <Grid item xs={6} >
                    <Box
                        component="img"
                        sx={{
                        height: 200,
                        width: 400,
                        border: 1,
                        }}
                        src="/thumb2.png"
                    />
                </Grid>


                <Grid item xs={12} >
                    <Typography variant="h5" component="h5" color={'#000000'} marginBottom={'10px'}><b>Ethical Categories</b> </Typography>
                </Grid>


                
                <Grid item xs={6} >
                    <p>
                        On the categories page, the user selects the size of company first. The categories page then lists all the Categories (for example, Legal, Ownership) and then compares the scores of the companies which falls in the selected size of company.
                    </p>
                </Grid>


                
                <Grid item xs={6} >
                    <Box
                        component="img"
                        sx={{
                        height: 200,
                        width: 400,
                        border: 1,
                        }}
                        src="/thumb3.png"
                    />
                </Grid>



                <Grid item xs={12} >
                    <Typography variant="h5" component="h5" color={'#000000'} marginBottom={'10px'}><b>Comparisons</b> </Typography>
                </Grid>


                
                <Grid item xs={6} >
                    <p>
                        For comparison, the user selects a company. That company is then compared to other companies of similar size accross several metrics, inculding the Core Compliance vs Values Alignment Analysis and the Supplier vs Staff Analysis.

                    </p>
                </Grid>


                
                <Grid item xs={6} >
                    <Box
                        component="img"
                        sx={{
                        height: 200,
                        width: 400,
                        border: 1,
                        }}
                        src="/thumb4.png"
                    />
                </Grid>






            </Grid>
        </div>
    );
  }
  
  export default Description;
  
